import React, { Fragment, useContext, useEffect, useState } from "react";
import {
  Header,
  Footer,
  AddOnAdvice,
  Loading,
  WLHInput,
  ModelModal,
  HistoryList,
  UsedBuildingDetails,
  Pager,
  BundleInfo,
  InventoryStatusBadge,
} from "./";
import { useParams, Redirect, useHistory } from "react-router-dom";
import Select from "react-select";
import DatePicker from "react-datepicker";
import classnames from "classnames";
import CurrencyInput from "react-currency-input-field";
import _ from "lodash";
import { confirmAlert } from "react-confirm-alert";
import {
  Button,
  ButtonGroup,
  Card,
  Form,
  FormGroup,
  Label,
  Input,
  InputGroup,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Table,
  Badge,
  UncontrolledTooltip,
  Spinner,
} from "reactstrap";
import ToggleButton from "react-toggle-button";
import FloorplanTool from "../floorplan-module-2.0/FloorplanTool";
import Alert from "react-s-alert-v3";
import { DebounceInput } from "react-debounce-input";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { api, constants, UserContext } from "../utils";
import { date_helpers, ui_helpers, floorplan_helpers } from "../helpers";
import SubProductSKU from "./SubProductSKU";
let lastSkuLoad = "";

const emptyInventory = {
  inventoryOn: date_helpers.getCurrentDate(),
  paidOn: new Date().toDateString(),
  serialNumber: "",
  contractId: 0,
  contract: null,
  productType: null,
  productTypeId: 0,
  productTypeOtherText: "",
  unitType: null,
  unitTypeId: 0,
  unitTypeOtherText: "",
  unitManufacturerSubProduct: null,
  unitManufacturerSubProductId: 0,
  unitManufacturerSubProductText: "",
  orderNote: "",
  manufacturerBrand: null,
  manufacturerBrandId: 0,
  manufacturerBrandName: "",
  manufacturerOtherText: "",
  manufacturer: null,
  manufacturerId: 0,
  manufacturerName: "",
  modelNumber: 0,
  dealer: null,
  dealerId: 0,
  company: null,
  companyId: 0,
  unitOptionsList: [],
  unitWidthFeet: 0,
  unitWidthFeetOtherText: "",
  unitLengthFeet: 0,
  unitLengthFeetOtherText: "",
  unitHeightFeet: 0,
  unitHeightFeetOtherText: "",
  unitManufacturerBaseColor: null,
  unitManufacturerBaseColorId: 0,
  unitManufacturerBaseColorText: "",
  unitManufacturerTrimColor: null,
  unitManufacturerTrimColorId: 0,
  unitManufacturerTrimColorText: "",
  unitManufacturerRoofColor: null,
  unitManufacturerRoofColorId: 0,
  unitManufacturerRoofColorText: "",
  unitIsNew: true,
  retailPrice: 0.0,
  wholesaleDiscountPercent: 0,
  wholesalePrice: 0,
  invoiceNumber: 0,
  webVisible: null,
  webSeq: null,
  wCProductId: null,
  bundlePrice: 0.0,
  qty: 1,
  unitManufacturerSubProductSku: null, //constants.OTHER_OPTION,
  associatedBundles: [],
  bundlesToDelete: [],
  typeOfPorch: null,
  porchMin: 0,
  porchMax: 0,
  porchWall1Length: 0,
  porchWall2Length: 0,
  porchWall3Length: 0,
  porchWall4Length: 0,
  overrideSerial: false,
};

const TABS = {
  INVENTORY_DETAILS: "inventory_details", //1,
  INVENTORY_IMAGES: "inventory_images", //2,
  COMMENTS: "comments",
  HISTORY: "history",
  USED_BUILDING_DETAILS: "used_building_details",
  BUILD_FEATURE: "build_feature",
};

const commentTemplate = {
  id: 0,
  userId: 0,
  dsc: "",
  isPrivate: false,
};

const popOutStyle = {
  position: "fixed",
  width: "100vw",
  height: "100vh",
  top: 0,
  left: 0,
  //padding: "1rem 2rem",
  backgroundColor: "#efefef",
  //overflow: "auto",
  zIndex: 900,
};

// const RedStar = () => ui_helpers.requiredStar();

function TabNavigator({ activeTab, setActiveTab, hasRepo, canEditBuild, isUsed, inventoryId }) {
  const history = useHistory();
  return (
    <Row>
      <Col xs="10">
        <Nav tabs>
          <NavItem>
            <NavLink
              style={{ cursor: "pointer" }}
              onClick={() => setActiveTab(TABS.INVENTORY_DETAILS)}
              active={activeTab === TABS.INVENTORY_DETAILS}
            >
              Inventory Details
            </NavLink>
          </NavItem>
          {(isUsed || hasRepo) && (
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                onClick={() => setActiveTab(TABS.USED_BUILDING_DETAILS)}
                active={activeTab === TABS.USED_BUILDING_DETAILS}
              >
                Used Building Details
              </NavLink>
            </NavItem>
          )}
          <NavItem>
            <NavLink
              style={{ cursor: "pointer" }}
              onClick={() => setActiveTab(TABS.HISTORY)}
              active={activeTab === TABS.HISTORY}
            >
              History
            </NavLink>
          </NavItem>
        </Nav>
      </Col>
      {inventoryId ? (
        <Col xs="2">
          <Button
            color="dark"
            size="sm"
            className="float-end"
            onClick={() => history.push(`${constants.PATH_NAMES.INVENTORY_VIEW}/${inventoryId}`)}
            style={{ width: "120px", fontWeight: "bold" }}
          >
            Exit
          </Button>
        </Col>
      ) : null}
    </Row>
  );
}

function CFLabel({ label, required, subLabel, paired }) {
  return (
    <Row>
      <Col sm={subLabel ? 2 : ""}>
        {label} {required ? ui_helpers.requiredStar() : null}{" "}
        {paired ? (
          <Badge color="success" title="Contract Paired" className="ml-2">
            Contract Paired
          </Badge>
        ) : null}
      </Col>
      {subLabel ? (
        <Col>
          <small style={{ fontSize: "0.8rem" }}>{subLabel}</small>
        </Col>
      ) : null}
    </Row>
  );
}

function CFFormGroup({
  label,
  required,
  labelOnly,
  type,
  subLabel,
  placeholder,
  bsSize,
  classNames,
  ...rest
}) {
  if (labelOnly)
    return (
      <FormGroup>
        <CFLabel label={label} required={required} />
        {rest.children}
      </FormGroup>
    );
  if (type === "select")
    return (
      <FormGroup>
        <CFLabel label={label} required={required} />
        <Select {...rest} />
      </FormGroup>
    );
  if (type === "date")
    return (
      <FormGroup className={"size-form-group"}>
        <CFLabel label={label} required={required} subLabel={subLabel} />
        <div className="datePickerDiv">
          <DatePicker
            showMonthDropdown
            showYearDropdown
            withPortal
            dropdownMode="select"
            name="inventoryOn"
            {...rest}
          />
        </div>
      </FormGroup>
    );
  return (
    <FormGroup classNames={classNames}>
      <CFLabel label={label} required={required} paired={rest.paired} />
      {bsSize ? <Input bsSize={bsSize} type={type} {...rest} /> : <Input type={type} {...rest} />}
    </FormGroup>
  );
}

function InventoryEdit() {
  const { inventoryId } = useParams();
  const [resolvedInventoryId, setResolvedInventoryId] = useState(parseInt(inventoryId, 10));
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingFilter, setIsLoadingFilter] = useState(false);
  const [showModelModal, setShowModelModal] = useState(false);
  const [activeTab, setActiveTab] = useState(TABS.INVENTORY_DETAILS);
  const [companyOptions, setCompanyOptions] = useState([]);
  const [companyDealerOptions, setCompanyDealerOptions] = useState([]);
  const [dealerOptions, setDealerOptions] = useState([]);
  const [mfgOptions, setMfgOptions] = useState([]);
  const [pristineSubProductOptions, setPristineSubProductOptions] = useState([]);
  //const [prestineAddonList, setPrestineAddonList] = useState(null);
  const [productSkuOptions, setProductSkuOptions] = useState([]);
  const [unitTypeOptions, setUnitTypeOptions] = useState([]);
  const [subProductOptions, setSubProductOptions] = useState([]);
  const [baseColorOptions, setBaseColorOptions] = useState([]);
  const [trimColorOptions, setTrimColorOptions] = useState([]);
  const [roofColorOptions, setRoofColorOptions] = useState([]);
  const [unitOptionPrice, setUnitOptionPrice] = useState(0.0);
  const [addOns, setAddOns] = useState([]);
  const [inventory, setInventory] = useState(Object.assign({}, emptyInventory));
  const [attachments, setAttachments] = useState([]);
  const [historyList, setHistoryList] = useState([]);
  const [wValue, setWValue] = useState(null);
  const [lValue, setLValue] = useState(null);
  const [hValue, setHValue] = useState(null);
  const [wOtherTxt, setWOtherTxt] = useState("");
  const [lOtherTxt, setLOtherTxt] = useState("");
  const [hOtherTxt, setHOtherTxt] = useState("");
  const [unitSelectionState, setUnitSelectionState] = useState(null);
  const [otherAddOnName, setOtherAddOnName] = useState("");
  const [showCommentModal, setShowCommentModal] = useState(false);
  const [comment, setComment] = useState(commentTemplate);
  const [hasBuildFeature, setHasBuildFeature] = useState(false);
  const [mfgCustomSerialFeature, setMfgCustomSerialFeature] = useState(false);
  const [hideUnitOptionsFeature, setHideUnitOptionsFeature] = useState(false);
  const [defaultMfgUnitTypeId, setDefaultMfgUnitTypeId] = useState(null);
  const [sizeOptions, setSizeOptions] = useState({
    width: [],
    length: [],
  });
  const [modelNumber, setModelNumber] = useState("");
  const [commentPrivate, setCommentPrivate] = useState(false);
  const [comments, setComments] = useState([]);
  const [showEditor, setShowEditor] = useState(false);
  const { currentUser } = useContext(UserContext);
  const [redirectTo, setRedirectTo] = useState(null);
  const [contractList, setContractList] = useState([]);
  const [repo, setRepo] = useState(null);
  const [hasUnprocessedContract, setHasUnprocessedContract] = useState(null);
  const [pl, setPl] = useState(null);
  const [unitIsNew, setUnitIsNew] = useState(inventory && inventory.unitIsNew);
  const [contractPairingMode, setContractPairingMode] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [resolutionNeeded, setResolutionNeeded] = useState(false);
  const [productTypes, setProductTypes] = useState(null);
  const [hideWholesaleFields, setHideWholesaleFields] = useState(false);
  const [validSkuSelected, setValidSkuSelected] = useState(false);
  const [pairingOptions, setPairingOptions] = useState([]);
  const [showSearchingUnpairedSpinner, setShowSearchingUnpairedSpinner] = useState(false);
  const [wholesalePriceIsStale, setWholesalePriceIsStale] = useState(false);
  const [mfgWholeSaleDetails, setMfgWholeSaleDetails] = useState(null);
  const [isSystemAdmin, setIsSystemAdmin] = useState(false);
  const [canManageComments, setCanManageComments] = useState(false);
  const [canEdit, setCanEdit] = useState(false);
  const [pairedAddons, setPairedAddons] = useState([]);
  const [lastSkuId, setLastSkuId] = useState(0);
  //For FP
  const [selectedFPAddOns, setSelectedFPAddOns] = useState([]);
  const [optionColors, setOptionColors] = useState([]);
  const [mfgFPAddOnOptions, setMfgFPAddOnOptions] = useState([]);
  const [bundleOptions, setBundleOptions] = useState([]);
  const [multiIdList, setMultiIdList] = useState(null);
  const [hideFPExit, setHideFPExit] = useState(false);
  const [hasExistingFloorPlan, setHasExistingFloorPlan] = useState(false);

  useEffect(() => {
    const isSysAdmin = ui_helpers.isSystemAdministrator(currentUser);
    setIsSystemAdmin(isSysAdmin);
    const canMngInventory =
      inventory &&
      ui_helpers.canCreateInventory(
        currentUser,
        inventory.companyId,
        inventory.dealerId,
        inventory.manufacturerId
      );
    const isStoreUser = inventory && ui_helpers.isStoreUser(currentUser, inventory.dealerId);
    const canDispatch =
      isSysAdmin ||
      _.some(
        currentUser.roles,
        (ro) =>
          ((ro.manufacturerId === inventory.manufacturerId ||
            ro.companyId === inventory.rtoCompanyId ||
            ro.companyId === inventory.companyId) &&
            ro.typeOfUserRole === constants.ROLE_IDS.Administrator) ||
          ro.typeOfUserRole === constants.ROLE_IDS.Dispatcher ||
          (ro.dealerId === inventory.dealerId &&
            ro.typeOfUserRole === constants.ROLE_IDS.Dispatcher) ||
          (ro.companyId === inventory.companyId &&
            ro.typeOfUserRole === constants.ROLE_IDS.Dispatcher)
      );
    setCanManageComments(canMngInventory || isStoreUser || canDispatch || isSysAdmin);
    setCanEdit(
      isSysAdmin ||
        (inventory &&
          _.some(
            currentUser.roles,
            (ro) =>
              ro.companyId === inventory.companyId &&
              (ro.typeOfUserRole === constants.ROLE_IDS.Administrator ||
                ro.typeOfUserRole === constants.ROLE_IDS.InventoryManager)
          ))
    );
  }, [currentUser, inventory?.companyId, inventory?.dealerId, inventory?.manufacturerId]);

  useEffect(() => {
    if (resolvedInventoryId) {
      getInventoryData();
    } else {
      getFilterOptions();
    }
  }, []);

  useEffect(() => {
    if (!searchText) return;
    findUnpairedContracts();
  }, [searchText])

  useEffect(() => {
    if (inventory.manufacturerBrand?.value) {
    }
  }, [inventory.manufacturerBrand]);

  useEffect(() => {
    getMfgOptions();
    getMfgBuildFeature();
    setRetailPrice();
    getMfgSubproductSkuAddOnOptions();
  }, [
    inventory.manufacturerBrand && inventory.manufacturerBrand.value,
    inventory.unitManufacturerSubProduct && inventory.unitManufacturerSubProduct.value,
    inventory.productType && inventory.productType.value,
  ]);

  useEffect(() => {
    getMfgColorOptions();
    getSubProductSkuOptions();
  }, [inventory.unitManufacturerSubProduct && inventory.unitManufacturerSubProduct.value]);

  useEffect(() => {
    // recalc immediately on new, only occasionally with prompt for editing existing
    if (resolvedInventoryId) {
      setWholesalePriceIsStale(true);
    } else {
      calculateWholeSalePrice();
    }
  }, [inventory.retailPrice, inventory.wholesaleDiscountPercent]);

  useEffect(findUnpairedContracts, [contractList && contractList.pageNumber]);

  useEffect(() => {
    // console.log('effect 2', inventory.unitManufacturerSubProductSku, lastSkuId)
    if (
      isLoading ||
      isLoadingFilter ||
      !inventory.unitManufacturerSubProductSku ||
      !inventory.unitManufacturerSubProductSku.value
    )
      return;
    if (lastSkuId) {
      setInventory({
        ...inventory,
        unitOptionsList: [],
      });
    }
    getMfgSubproductSkuAddOnOptions();
  }, [inventory.unitManufacturerSubProductSku]);

  useEffect(resolveUnitDetails, [
    baseColorOptions,
    trimColorOptions,
    subProductOptions,
    roofColorOptions,
  ]);

  function getSkuLoadUrl(mfgId, skuId) {
    return `Inventory/GetMfgProductSkuAddOnAndDefaultOptions/${mfgId}/${skuId}`;
  }

  function resolveUnitDetails() {
    if (!resolutionNeeded) return;
    if (
      !baseColorOptions.length > 0 ||
      !trimColorOptions.length > 0 ||
      !roofColorOptions.length > 0 ||
      !subProductOptions.length > 0
    )
      return;
    let inv = _.cloneDeep(inventory);
    let unitOptions = {
      baseColorList: ui_helpers.valueLabelToIdName(baseColorOptions),
      trimColorList: ui_helpers.valueLabelToIdName(trimColorOptions),
      roofColorList: ui_helpers.valueLabelToIdName(roofColorOptions),
      subProductTypeList: ui_helpers.valueLabelToIdName(subProductOptions),
    };
    setInventory(formatInventory(inv, unitOptions, productSkuOptions, inv.unitOptionList));
    setResolutionNeeded(false);
  }

  function getMfgBuildFeature() {
    if (!inventory.manufacturerBrand || inventory.manufacturerBrand.value <= 0) {
      setHasBuildFeature(false);
      return;
    }
    api
      .fetch("Reference/GetMfgEnabledFeatures/" + inventory.manufacturerBrand.value)
      .then((r) => {
        if (!r.data) return;
        if (resolvedInventoryId === 0) {
          setHasBuildFeature(r.data.buildFeature);
        }
        setMfgCustomSerialFeature(r.data.customSerialFeature);
        setHideUnitOptionsFeature(r.data.hideUnitOptions);
        setDefaultMfgUnitTypeId(r.data.defaultUnitTypeId);
        if (!mfgWholeSaleDetails) {
          setInventory({
            ...inventory,
            wholesaleDiscountPercent: r.data.defaultWholesaleDiscountPercentage,
          });
        } else {
          setMfgWholeSaleDetails(null);
        }
      })
      .catch((err) => console.error(err));
  }

  function getFilteredProductList(mfgId) {
    if (!inventory.manufacturerBrand || !inventory.manufacturerBrand.value) return;
    api
      .fetch(`inventory/getFilteredProductTypes/${mfgId}`)
      .then((r) => {
        if (!r.data.success) return;
        if (r.data.message.length) {
          setProductTypes(
            ui_helpers.idNameToValueLabel(
              _.sortBy(r.data.message, (p) => p.value),
              null,
              false
            )
          );
        }
      })
      .catch(api.catchHandler);
  }

  function changeModelNumber() {
    api
      .post("inventory/SetModelNo", {
        id: resolvedInventoryId,
        modelNo: modelNumber,
      })
      .then((r) => {
        if (r.data.success) {
          handleInventoryChange("modelNumber", modelNumber);
          setShowModelModal(false);
          setModelNumber("");
        } else {
          Alert.error(r.data.message);
        }
      })
      .catch((e) => console.error(e));
  }

  function deleteComment(commentId, dsc) {
    // may have unsaved comments that require no api call
    if (commentId) {
      api.delete(`Inventory/DeleteComment/${commentId}`).then((r) => {
        if (!r.data.success) {
          console.error("failed to delete comment:", r.data.message);
        }
      });
    }
    setComments(_.reject(comments, (c) => c.id === commentId && c.dsc === dsc));
  }

  function onDeleteAttachment(i) {
    api
      .post("Inventory/DeactivateAttachment", { id: i.id })
      .then((r) => {
        if (r.data.success) {
          getInventoryData();
        } else {
          Alert.error(r.data.message);
        }
      })
      .catch((e) => console.error(e));
  }

  function onDownloadAttachment(e, docId) {
    e.preventDefault();
    const pdfWindow = window.open();
    api.downloadAndOpenFile(
      `inventory/GetDocument/${resolvedInventoryId}/${docId}`,
      {},
      pdfWindow,
      (e) => {
        pdfWindow.close();
        console.error(e);
        Alert.error("There was an error downloading the document");
      }
    );
  }

  async function getFilterOptions() {
    setIsLoadingFilter(true);
    api
      .fetch("Inventory/GetInventoryFilters?withInitialSearchResults=false")
      .then((r) => {
        if (!r.data.data) return;
        setCompanyOptions(r.data.data.companySelect);
        const cleanDealerList = _.reject(r.data.data.dealerSelect, ["value", -999]);
        setDealerOptions(ensureOtherEntry(cleanDealerList));
        setCompanyDealerOptions(cleanDealerList);
        setMfgOptions(r.data.data.manufacturerSelect);
        setProductTypes(_.orderBy(r.data.data.productTypeSelect, ["value"], ["asc"]));
        let newInv = { ...inventory };
        if (r.data.data.selectedCompany) {
          let defaultCompany = r.data.data.selectedCompany;
          defaultCompany.value = defaultCompany.id;
          defaultCompany.label = defaultCompany.name;
          newInv.company = defaultCompany;
          if (
            r.data.data.companySelect.length <= 2 &&
            r.data.data.companySelect.length > 0 &&
            defaultCompany.captiveToManufacturerId !== null
          ) {
            // 1 default company and/or a "-Select-" entry
            setHideWholesaleFields(true);
          }
        }
        if (r.data.data.defaultManufacturer) {
          let defaultManufacturer = r.data.data.defaultManufacturer;
          defaultManufacturer.value = defaultManufacturer.id;
          defaultManufacturer.label = defaultManufacturer.name;
          newInv.manufacturerBrand = defaultManufacturer; //remove below
          newInv.manufacturerBrandId = r.data.data.defaultManufacturer.id;
          newInv.manufacturerBrandName = r.data.data.defaultManufacturer.name;
          loadMfgDealers(defaultManufacturer.value);
          if (cleanDealerList.length === 1) {
            newInv.dealer = cleanDealerList[0];
          }
        }
        setInventory(newInv);
      })
      .catch(api.catchHandler)
      .finally(() => setIsLoadingFilter(false));
  }

  const addOtherOptionToDimension = (dimensionArray) => {
    let newArray = dimensionArray.slice();
    return newArray;
  };

  const getSubProductSizeOptions = () => {
    if (!inventory.unitManufacturerSubProduct) return;
    api
      .fetch("manufacturer/getSubProductSizes/" + inventory.unitManufacturerSubProduct.value)
      .then((r) => {
        if (!r.data) {
          Alert.error("There was an issue getting the size options for this Product.");
          return;
        }
        let width = [];
        let length = [];
        if (r.data.width.length > 0) {
          width = r.data.width;
          width = addOtherOptionToDimension(width);
        } else {
          width = constants.DIMENSIONS.W;
        }
        if (r.data.length.length > 0) {
          length = r.data.length;
          length = addOtherOptionToDimension(length);
        } else {
          length = constants.DIMENSIONS.L;
        }
        setSizeOptions({
          width: width,
          length: length,
        });
      })
      .catch((err) => console.error("There was an issue fetching size options: " + err));
  };

  function resolveSubproductId() {
    if (
      !inventory.unitManufacturerSubProduct &&
      (!inventory.unitManufacturerSubProductId || inventory.unitManufacturerSubProductId === 0)
    )
      return null;
    // let sProductId = inventory.unitManufacturerSubProductId > 0
    //     ? inventory.unitManufacturerSubProductId
    //     : inventory.unitManufacturerSubProduct.value;
    return inventory.unitManufacturerSubProduct
      ? inventory.unitManufacturerSubProduct.value
      : inventory.unitManufacturerSubProductId;
  }

  function getSubProductSkuOptions() {
    const sProductId = resolveSubproductId();
    if (!sProductId) return;
    if (sProductId === constants.OTHER_ID) {
      setProductSkuOptions([constants.OTHER_OPTION]);
      setInventory({ ...inventory, unitManufacturerSubProductSku: constants.OTHER_OPTION });
      return;
    }
    api
      .fetch("manufacturer/GetMfgSubProductSkuOptions/" + sProductId)
      .then((r) => {
        if (!r.data) {
          Alert.error("There was an issue getting the sku options for this Product.");
          return;
        }
        let list = _.map(r.data, (d) => {
          return { ...d, value: d.id, label: `${d.name} (${d.width}W x ${d.length}L)` };
        });
        list.unshift(constants.OTHER_OPTION);
        setProductSkuOptions(list);
        if (!inventory.unitManufacturerSubProductSku) {
          setInventory({ ...inventory, unitManufacturerSubProductSku: constants.OTHER_OPTION });
        }
      })
      .catch((err) => console.error("There was an issue fetching size options: " + err));
  }

  async function getMfgColorOptions() {
    const sProductId = resolveSubproductId();
    if (!sProductId) return;
    if (
      inventory.unitManufacturerSubProduct &&
      inventory.unitManufacturerSubProduct.label === constants.OTHER
    ) {
      const otherList = [constants.OTHER_OPTION];
      setBaseColorOptions(otherList);
      setTrimColorOptions(otherList);
      setRoofColorOptions(otherList);
      setInventory({
        ...inventory,
        unitManufacturerBaseColor: constants.OTHER_OPTION,
        unitManufacturerTrimColor: constants.OTHER_OPTION,
        unitManufacturerRoofColor: constants.OTHER_OPTION,
      });
      return;
    }
    const res = await api.fetch(
      `Manufacturer/ListManufacturerColorOptions/${inventory.manufacturerBrand.value}/${sProductId}`
    );
    if (res.data && res.data.data) {
      let baseColors = ui_helpers.sortColors(res.data.data.colorOptions, "Base");
      let trimColors = ui_helpers.sortColors(res.data.data.colorOptions, "Trim");
      let roofColors = ui_helpers.sortColors(res.data.data.colorOptions, "Roof");
      baseColors.push(constants.OTHER_OPTION);
      trimColors.push(constants.OTHER_OPTION);
      roofColors.push(constants.OTHER_OPTION);
      setBaseColorOptions(baseColors);
      setTrimColorOptions(trimColors);
      setRoofColorOptions(roofColors);
    }
  }

  function updateInventoryAddons(list, promptRecalculateWholesale = false) {
    if (!resolvedInventoryId) return;
    api
      .post("inventory/UpdateInventoryAddonOption", {
        InventoryId: resolvedInventoryId,
        AddonOptionList: list,
      })
      .then((res) => {
        if (!res.data || (res.data && !res.data.success)) {
          console.error("There was an error updating inventory addons");
          return null;
        }
        setInventory({
          ...inventory,
          unitOptionsList: res.data.message.addOnList,
        });
        setPairedAddons(list);
        if (promptRecalculateWholesale) {
          onMaybeRecalcWholesalePrice();
        }
      })
      .catch((er) => console.error(er));
  }

  function getMfgSubproductSkuAddOnOptions(
    forceReplaceAddOns = false,
    promptRecalculateWholesale = false
  ) {
    const mfgId = inventory.manufacturerBrand
      ? inventory.manufacturerBrand.value
      : inventory.manufacturerId;
    if (
      inventory.manufacturerBrand &&
      inventory.manufacturerBrand.label !== constants.OTHER &&
      inventory.unitManufacturerSubProductSku &&
      inventory.unitManufacturerSubProductSku.label !== constants.OTHER
    ) {
      const url = getSkuLoadUrl(mfgId, inventory.unitManufacturerSubProductSku.value);
      if (url === lastSkuLoad) return;
      lastSkuLoad = url;
      api
        .fetch(lastSkuLoad)
        .then((res) => {
          if (!res.data) return;
          setAddOns(addOtherIfMissing(mapAddOnsByContext("sku", res.data.mfgUnitOptions)));
          let inv = _.cloneDeep(inventory);
          let addOnList = inv.unitOptionsList;
          let defaultAddons = res.data.mfgDefaultSkuTemplateOptions;
          if (defaultAddons) {
            defaultAddons = _.map(defaultAddons, (x) => ({
              addOnId: x.addOnId,
              bundleId: x.bundleId,
              description: "",
              hasDimensions: true,
              id: 0,
              length: x.length,
              name: x.addOnName,
              price: x.price,
              seq: 1,
              showInFloorPlan: true,
              width: x.width,
              isDefaultAddon: true,
              positionX: x.positionX,
              positionY: x.positionY,
              rotation: x.rotation,
              typeOfUnitOfMeasure: x.unitOfMeasureId,
              swingTypeId: x.typeOfSwing || 0,
              additionalOption1Type: x.additionalOption1Type,
              additionalOption2Type: x.additionalOption2Type,
              additionalOption1On: x.additionalOption1On,
              additionalOption2On: x.additionalOption2On,
            }));
            defaultAddons = _.reject(
              mapAddOnsByContext("sku", defaultAddons),
              (x) => x.value === constants.OTHER_ID
            );
            if (resolvedInventoryId && !forceReplaceAddOns) {
              if (_.some(pairedAddons)) {
                addOnList = pairedAddons;
              } else {
                _.each(defaultAddons, (u) => {
                  if (!_.some(addOnList, (x) => x.addOnId === u.addOnId)) {
                    addOnList.push(u);
                  }
                });
              }
              updateInventoryAddons(addOnList, promptRecalculateWholesale);
            } else {
              addOnList = defaultAddons;
              setInventory({
                ...inv,
                unitOptionsList: addOnList,
              });
              if (promptRecalculateWholesale) {
                onMaybeRecalcWholesalePrice();
              }
            }
          }
        })
        .catch(api.catchHandler);
    } else if (
      mfgId &&
      mfgId !== constants.OTHER_ID &&
      inventory.productType &&
      inventory.productType.value !== constants.OTHER_ID &&
      (!inventory.unitManufacturerSubProductSku ||
        inventory.unitManufacturerSubProductSku.value === constants.OTHER_ID)
    ) {
      // console.log('load b1')
      api
        .post("inventory/ListManufacturerAddons", { activeOnly: true, id: mfgId })
        .then((r) => {
          if (!r.data.success) return;
          setAddOns(addOtherIfMissing(mapAddOnsByContext("manufacturer", r.data.message)));
          if (promptRecalculateWholesale) {
            onMaybeRecalcWholesalePrice();
          }
        })
        .catch(api.catchHandler);
    } else {
      // console.log('load c1')
      setAddOns(addOtherIfMissing([]));
      if (promptRecalculateWholesale && inventory.unitManufacturerSubProductSku) {
        onMaybeRecalcWholesalePrice();
      }
    }
  }

  function addOtherIfMissing(list) {
    if (_.some(list, (x) => x.label === constants.OTHER)) return list;
    list.push(constants.OTHER_OPTION);
    return list;
  }

  async function getMfgOptions() {
    const mfgId = inventory.manufacturerBrand ? inventory.manufacturerBrand.value : null;
    if (inventory.manufacturerBrand && inventory.productType && inventory.productType.value > 0) {
      const res = await api.fetch(
        `Inventory/GetMfgProductOptions/${mfgId}/${inventory.productType.value}`
      );
      if (res.data) {
        const unitTypeList = ui_helpers.idNameToValueLabel(res.data.unitTypeList);
        const subProductTypeList = ui_helpers.idNameToValueLabel(res.data.subProductTypeList);
        const newSubProductTypeList = ui_helpers.reOrderMyArrayList(subProductTypeList);
        const newUnitTypeList = ui_helpers.reOrderMyArrayList(unitTypeList);
        setPristineSubProductOptions(newSubProductTypeList);
        const list = inventory.productType
          ? _.filter(
              newSubProductTypeList,
              (p) => p.label === constants.OTHER || p.productTypeId === inventory.productType.value
            )
          : newSubProductTypeList;
        setSubProductOptions(list);
        setUnitTypeOptions(newUnitTypeList);
        const subProduct =
          inventory.unitManufacturerSubProduct === null &&
          inventory.unitManufacturerSubProductText.length
            ? _.find(subProductTypeList, (s) => s.name === inventory.unitManufacturerSubProductText)
            : null;
        if (subProduct) {
          setInventory({ ...inventory, unitManufacturerSubProduct: subProduct });
        }
        getFilteredProductList(mfgId);
      }
    } else {
      getFilteredProductList(mfgId);
    }
  }

  async function getInventoryData(changedId = null) {
    setIsLoading(true);
    const invId = changedId ? changedId : resolvedInventoryId;
    if (!invId) return;
    api
      .fetch(`Inventory/GetInventoryEditItem/${invId}`)
      .then((r) => {
        if (!r.data) return;
        const {
          inventoryData,
          dealers,
          companies,
          manufacturers,
          unitOptions,
          manufacturerSubProductSkus,
        } = r.data;
        setCompanyOptions(companies);
        setMfgOptions(manufacturers);
        setBaseColorOptions(ui_helpers.idNameToValueLabel(unitOptions.baseColorList));
        setTrimColorOptions(ui_helpers.idNameToValueLabel(unitOptions.trimColorList));
        setRoofColorOptions(ui_helpers.idNameToValueLabel(unitOptions.roofColorList));
        setSubProductOptions(ui_helpers.idNameToValueLabel(unitOptions.subProductTypeList));
        setPristineSubProductOptions(ui_helpers.idNameToValueLabel(unitOptions.subProductTypeList));
        setDealerOptions(ensureOtherEntry(dealers));
        setComments(inventoryData.commentList);
        setAttachments(inventoryData.attachmentList);
        if (inventoryData.repo) {
          if (inventoryData.repo.deliveredOn) {
            inventoryData.repo.deliveredOn = date_helpers.formatDateToShortDate(
              inventoryData.repo.deliveredOn
            );
          }
          if (inventoryData.repo.originalCustomerSSN) {
            inventoryData.repo.originalCustomerSSN = ui_helpers.formatSSNField(
              inventoryData.repo.originalCustomerSSN
            );
          }
          if (inventoryData.repo.originalDealerId) {
            inventoryData.repo.originalDealer = {
              label: inventoryData.repo.originalDealerName,
              value: inventoryData.repo.originalDealerId,
            };
          } else {
            inventoryData.repo.originalDealer = constants.OTHER_OPTION;
          }
        }
        setRepo(inventoryData.repo);
        setHistoryList(inventoryData.lifeCycleEventList);
        if (!manufacturerSubProductSkus.length) {
          manufacturerSubProductSkus.push(constants.OTHER_OPTION);
        }
        setProductSkuOptions(manufacturerSubProductSkus);
        let inv = inventoryData.inventory;
        setMfgWholeSaleDetails({
          mfgId: inv.manufacturerBrandId,
          wholesaleDiscount: inv.wholesaleDiscountPercent,
        });
        setLastSkuId(inventoryData.inventory.unitManufacturerSubProductSkuId);
        lastSkuLoad = getSkuLoadUrl(
          inv.manufacturerBrandId,
          inventoryData.inventory.unitManufacturerSubProductSkuId
        );
        setInventory(
          formatInventory(
            inventoryData.inventory,
            unitOptions,
            manufacturerSubProductSkus,
            inventoryData.unitOptionList
          )
        );
        setHasBuildFeature(inventoryData.inventory.hasBuildFeature);
        setHasUnprocessedContract(inventoryData.hasUnprocessedContract);
        setPl(inventoryData.pl);
        setPairedAddons(inventoryData.unitOptionList);
      })
      .catch((err) => {
        console.error(err, "error in getInventoryData");
        Alert.error("There was an error retrieving this Inventory item.");
      })
      .finally(() => setIsLoading(false));
  }

  function formatInventory(inv, unitOptions, manufacturerSubProductSkus, inventoryAddOns) {
    let baseColor = _.find(
      unitOptions.baseColorList,
      (b) => b.name === inv.unitManufacturerBaseColorText
    );
    let trimColor = _.find(
      unitOptions.trimColorList,
      (b) => b.name === inv.unitManufacturerTrimColorText
    );
    let roofColor = _.find(
      unitOptions.roofColorList,
      (b) => b.name === inv.unitManufacturerRoofColorText
    );
    let productSku = _.find(
      manufacturerSubProductSkus,
      (b) => b.id === inv.unitManufacturerSubProductSkuId
    );
    let subProduct = _.find(
      unitOptions.subProductTypeList,
      (b) => b.name === inv.unitManufacturerSubProductText
    );
    let formattedInv = {
      ...inv,
      inventoryOn: date_helpers.formatDateToShortDate(inv.inventoryOn),
      productType: ui_helpers.mapIdNameToValueLabel(inv.productTypeId, inv.productTypeName),
      unitManufacturerSubProduct:
        subProduct === undefined
          ? constants.OTHER_OPTION
          : ui_helpers.mapPropToValueLabel(subProduct),
      company: ui_helpers.mapIdNameToValueLabel(inv.companyId, inv.companyName),
      manufacturer: ui_helpers.mapIdNameToValueLabel(inv.manufacturerId, inv.manufacturerName),
      manufacturerBrand:
        inv.manufacturerBrandId === constants.OTHER_ID
          ? constants.OTHER_OPTION
          : ui_helpers.mapIdNameToValueLabel(
              inv.manufacturerBrandId,
              inv.manufacturerBrandName || inv.manufacturerName
            ),
      dealer: ui_helpers.mapIdNameToValueLabel(inv.dealerId, inv.dealerName),
      unitType:
        inv.unitTypeId === constants.OTHER_ID
          ? constants.OTHER_OPTION
          : ui_helpers.mapIdNameToValueLabel(inv.unitTypeId, inv.unitTypeName),
      unitManufacturerSubProductSku: productSku
        ? ui_helpers.mapPropToValueLabel(productSku)
        : constants.OTHER_OPTION,
      unitManufacturerBaseColor: baseColor
        ? ui_helpers.mapPropToValueLabel(baseColor)
        : constants.OTHER_OPTION,
      unitManufacturerTrimColor: trimColor
        ? ui_helpers.mapPropToValueLabel(trimColor)
        : constants.OTHER_OPTION,
      unitManufacturerRoofColor: roofColor
        ? ui_helpers.mapPropToValueLabel(roofColor)
        : constants.OTHER_OPTION,
      unitWidthFeet: inv.unitWidthFeet,
      unitLengthFeet: inv.unitLengthFeet,
      unitWidthFeetOtherText: productSku
        ? ""
        : inv.unitWidthFeet > 0
        ? inv.unitWidthFeet
        : inv.unitWidthFeetOtherText,
      unitLengthFeetOtherText: productSku
        ? ""
        : inv.unitLengthFeet > 0
        ? inv.unitLengthFeet
        : inv.unitLengthFeetOtherText,
      unitHeightFeetOtherText: productSku
        ? ""
        : inv.unitHeightFeet > 0
        ? inv.unitHeightFeet
        : inv.unitHeightFeetOtherText,
      unitOptionsList: inventoryAddOns || [],
    };
    if (!productSku) {
      setWOtherTxt(formattedInv.unitWidthFeetOtherText);
      setLOtherTxt(formattedInv.unitLengthFeetOtherText);
      setHOtherTxt(formattedInv.unitHeightFeetOtherText);
    }
    setMfgWholeSaleDetails(null);
    setWholesalePriceIsStale(true);
    return formattedInv;
  }

  async function getNextInvoiceNumber() {
    const res = await api.fetch("Inventory/GenerateInventoryNumber");
    if (res.data) handleInventoryChange("invoiceNumber", res.data);
  }

  function ensureOtherEntry(list) {
    if (_.some(list, (x) => x.label === constants.OTHER)) return list;
    return [constants.OTHER_OPTION, ...list];
  }

  function loadMfgDealers(mfgId) {
    api
      .fetch(`inventory/GetRestrictedManufacturerDealerList/${mfgId}`)
      .then((res) => {
        if (res.data) {
          let newDealerList = res.data.data; // _.map(res.data.data, x => ({value: x.dealerId, label: x.dealerName}));
          if (!newDealerList.length) {
            newDealerList = companyDealerOptions;
          }
          setDealerOptions(ensureOtherEntry(newDealerList));
          if (inventory.dealer && !_.some(newDealerList, (d) => d.id === inventory.dealer.value)) {
            newInventory.dealer = null;
            setInventory(newInventory);
          }
        }
      })
      .catch((err) => console.error(err));
  }

  function handleInventoryChange(property, selectedValue) {
    const newInventory = _.cloneDeep(inventory);
    newInventory[property] = selectedValue;
    switch (property) {
      case "manufacturerBrand":
        if (selectedValue) {
          loadMfgDealers(selectedValue.value);
          getFilteredProductList(selectedValue.value);
        }
        break;
      case "productType":
        const subPrdtTypes = _.filter(
          pristineSubProductOptions,
          (p) =>
            p.label === constants.OTHER || p.productTypeId === parseInt(selectedValue.value, 10)
        );
        if (
          subPrdtTypes.length &&
          (!newInventory.unitManufacturerSubProduct ||
            newInventory.unitManufacturerSubProduct.value !== 0 ||
            newInventory.unitManufacturerSubProduct.value !== constants.OTHER_ID)
        ) {
          newInventory.unitManufacturerSubProduct = subPrdtTypes[0];
        } else {
          newInventory.unitManufacturerSubProduct = null;
        }
        newInventory.unitManufacturerBaseColor = null;
        newInventory.unitManufacturerTrimColor = null;
        newInventory.unitManufacturerRoofColor = null;
        newInventory.unitType = null;

        //update size fields
        newInventory.unitManufacturerSubProductSku = null;
        setWValue(null);
        setLValue(null);
        setHValue(null);
        setWOtherTxt("");
        setLOtherTxt("");
        setHOtherTxt("");
        if (subPrdtTypes.length) {
          setSubProductOptions(subPrdtTypes);
        } else {
          const list = [constants.OTHER_OPTION];
          setSubProductOptions(list);
          setUnitTypeOptions(list);
          setProductSkuOptions(list);
        }
        break;
      case "unitManufacturerSubProductSku":
        setWValue(selectedValue.width);
        setLValue(selectedValue.length);
        newInventory.retailPrice =
          selectedValue.label === constants.OTHER ? 0.0 : selectedValue.defaultPrice;
        break;
      case "contract":
        newInventory.serialNumber = selectedValue.serialNumber;
        break;
      case "unitType":
        if (selectedValue.label === constants.OTHER) {
          let list = [];
          list.push(constants.OTHER_OPTION);
          setBaseColorOptions(list);
          setTrimColorOptions(list);
          setRoofColorOptions(list);
        }
        break;
      case "bundlePrice":
        newInventory.bundlePrice = selectedValue;
        newInventory.retailPrice += selectedValue;
        break;
    }
    setInventory(newInventory);
  }

  function onMaybeRecalcWholesalePrice() {
    if (wholesalePriceIsStale && !hideWholesaleFields) {
      setWholesalePriceIsStale(false);
      calculateWholeSalePrice();
    }
  }

  function calculateWholeSalePrice() {
    if (isLoading || isLoadingFilter || hideWholesaleFields) return;
    // only prompt if this is a pre-existing item being edited
    if (resolvedInventoryId) {
      confirmAlert({
        title: "Recalculate Wholesale Price",
        message: `Either retail price or the wholesale discount % has changed. Would you like to recalculate and update the wholesale price automatically?`,
        buttons: [
          {
            label: "Yes",
            onClick: updateWholesalePrice,
          },
          {
            label: "No",
          },
        ],
      });
      return;
    }
    updateWholesalePrice();
  }

  function updateWholesalePrice() {
    let inv = _.cloneDeep(inventory);
    inv.wholesalePrice =
      Number(inv.retailPrice) -
      Number(inv.retailPrice) * (Number(inv.wholesaleDiscountPercent) / 100);
    setInventory(inv);
  }

  function setRetailPrice() {
    if (!inventory.unitManufacturerSubProduct) return;
    if (hasBuildFeature) {
      let subPrdtType = _.find(
        subProductOptions,
        (o) => o.id === Number(inventory.unitManufacturerSubProduct.value)
      );
      if (subPrdtType !== undefined) {
        const newInventory = _.cloneDeep(inventory);
        newInventory.retailPrice = subPrdtType.basePrice;
        setInventory(newInventory);
      }
    }
  }

  function onInventorySelectChange(e, fieldName) {
    handleInventoryChange(fieldName, e);
  }

  function onInventoryChange(e) {
    handleInventoryChange(e.target.name, e.target.value);
  }

  function onInventoryOnDateChange(e) {
    handleInventoryChange("inventoryOn", date_helpers.formatDateToShortDate(e));
  }

  function onBlur(e) {
    switch (e.target.name) {
      case "serialNumber":
        if (e.target.value.length > 3) {
          api
            .post("Inventory/FindInventoryAssociations", {
              InventoryId: resolvedInventoryId,
              SerialNumber: e.target.value,
            })
            .then((response) => {
              if (response.data.success) {
                setPairingOptions(response.data.message);
              } else {
                Alert.error(response.data.message);
              }
            })
            .catch((err) => {
              console.error(err);
              Alert.error("An error occurred. Inventory pairing options could not be fetched.");
            });
        }
        break;
    }
  }

  function confirmPairing(pairingOption) {
    confirmAlert({
      title: "Confirm Pairing",
      message: `Inventory Unit will be created during pairing. Are you sure?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            pairTo(pairingOption);
          },
        },
        {
          label: "No",
        },
      ],
    });
  }

  function pairTo(pairingOption) {
    if (pairingOption.isInventory) {
      api
        .post("Inventory/ReinitializeInventory", { id: pairingOption.inventoryId })
        .then((response) => {
          if (response.data.success) {
            setPairingOptions([]);
            const newInventoryId = parseInt(response.data.message, 10);
            if (resolvedInventoryId === newInventoryId) {
              window.location.reload();
            } else {
              setResolvedInventoryId(newInventoryId);
              getInventoryData(newInventoryId); // reload
              setWholesalePriceIsStale(true);
            }
          } else {
            Alert.error(response.data.message);
          }
        });
    } else {
      const companyId = inventory.company ? inventory.company.value : 0;
      api
        .fetch(
          `Inventory/UpdateInventoryFromContractSerial/company/${companyId}/inventory/${resolvedInventoryId}/contract/${pairingOption.contractId}`
        )
        .then((response) => {
          if (response.data.success) {
            setPairingOptions([]);
            const newInventoryId = parseInt(response.data.message, 10);
            if (resolvedInventoryId === newInventoryId) {
              window.location.reload();
            } else {
              setResolvedInventoryId(newInventoryId);
              getInventoryData(newInventoryId); // reload
              setWholesalePriceIsStale(true);
            }
          } else {
            Alert.error(response.data.message);
          }
        });
    }
  }

  function mapAddOnsByContext(context, addons) {
    if (!addons || !addons.length) return [];
    let mappedAddOns = [];
    if (context === "sku") {
      mappedAddOns = _.map(addons, (d) => {
        d.label = `${d.name} - ${ui_helpers.formatCurrency(d.addOnStandardPrice)}`;
        d.value = d.id;
        return d;
      });
    } else if (context === "manufacturer") {
      mappedAddOns = _.map(addons, (d) => {
        d.label = `${d.name} - ${ui_helpers.formatCurrency(d.standardPrice)}`;
        d.value = d.id;
        d.addOnStandardPrice = d.standardPrice;
        return d;
      });
    }
    if (!_.some(mappedAddOns, (s) => s.name === constants.OTHER)) {
      mappedAddOns.unshift(constants.OTHER_OPTION);
    }
    return mappedAddOns;
  }

  function isInvValid() {
    let warnings = [];

    if (!inventory.manufacturerBrand) {
      warnings.push("Manufacturer is required");
    }

    if (!inventory.dealer) {
      warnings.push("Dealer is required");
    }

    if (!inventory.company) {
      warnings.push("Owned By Company is required");
    }

    if (!inventory.productType) {
      warnings.push("Product Type is required");
    }

    if (!inventory.unitManufacturerSubProduct) {
      warnings.push("Style is required");
    }

    if (inventory.unitManufacturerSubProduct && !inventory.unitManufacturerSubProductSku) {
      warnings.push("Size is required");
    }

    if (!hideUnitOptionsFeature && !inventory.unitType) {
      warnings.push("Material is required");
    }

    if (!inventory.unitManufacturerBaseColor) {
      warnings.push("Base Color is required");
    }

    if (!inventory.unitManufacturerTrimColor) {
      warnings.push("Trim Color is required");
    }

    if (!inventory.unitManufacturerRoofColor) {
      warnings.push("Roof Color is required");
    }

    if (inventory.invoiceNumber <= 0) {
      warnings.push("Valid Invoice Number is required");
    }

    if (!mfgCustomSerialFeature && !_.trim(inventory.serialNumber)) {
      warnings.push("Enter a serial # (TBD or MADE TO ORDER is acceptable if appropriate).");
    }
    if (mfgCustomSerialFeature && inventory.overrideSerial && !_.trim(inventory.serialNumber)) {
      warnings.push(
        "Either UNCHECK the override serial # box or enter a serial # (TBD or MADE TO ORDER is acceptable if appropriate)."
      );
    }
    if (repo && !_.isEmpty(repo) && !inventory.unitIsNew) {
      if (
        !repo.originalDealer ||
        (repo.originalDealer &&
          repo.originalDealer.label === constants.OTHER &&
          _.trim(repo.originalDealerOtherText) === "")
      ) {
        warnings.push("PNL Original Store is required.");
      }
      if (repo.originalSalespersonName.trim() === "") {
        warnings.push("PNL Original Sales Person is required.");
      }
      if (repo.originalCustomerFirstName.trim() === "") {
        warnings.push("PNL Original Customer First Name is required.");
      }
      if (repo.originalCustomerLastName.trim() === "") {
        warnings.push("PNL Original Customer Last Name is required.");
      }
      if (repo.originalPrice === null) {
        warnings.push("PNL Original Price is required.");
      }
      if (repo.rentalRevenue === null) {
        warnings.push("PNL Rental Revenue is required.");
      }
      if (repo.otherRevenue === null) {
        warnings.push("PNL Other Revenue is required.");
      }
      if (repo.pickupFee === null) {
        warnings.push("PNL Pickup Fee is required.");
      }
      if (repo.refurbFee === null) {
        warnings.push("PNL Refurb Fee is required.");
      }
      if (repo.redeliveryFee === null) {
        warnings.push("PNL Redelivery Fee is required.");
      }
      if (repo.commission === null) {
        warnings.push("PNL Commission is required.");
      }
    }

    if (warnings.length > 0) {
      Alert.error(warnings.join(", "));
    }
    return warnings.length === 0;
  }

  function saveInventoryItem() {
    if (!isInvValid()) return;
    let payload = {
      inventory: Object.assign({}, inventory),
      newComments: comments,
      UnitOptionList: inventory.unitOptionsList,
    };
    payload.inventory.hideMFGUnitType = hideUnitOptionsFeature;
    payload.inventory.productTypeId =
      payload.inventory.productType && payload.inventory.productType.label !== constants.OTHER
        ? payload.inventory.productType.value
        : constants.OTHER_ID;
    delete payload.inventory.productType;
    payload.inventory.ProductTypeOtherText =
      payload.inventory.productType && payload.inventory.productType.label === constants.OTHER
        ? payload.inventory.productTypeOtherText
        : null;

    payload.inventory.companyId =
      payload.inventory.company && payload.inventory.company.value
        ? payload.inventory.company.value
        : null;

    payload.inventory.dealerId =
      payload.inventory.dealer && payload.inventory.dealer.value
        ? payload.inventory.dealer.value
        : null;

    payload.inventory.manufacturerBrandName =
      payload.inventory.manufacturerBrand &&
      payload.inventory.manufacturerBrand.label === constants.OTHER
        ? payload.inventory.manufacturerBrandName
        : payload.inventory.manufacturerBrand.label;

    payload.inventory.manufacturerBrandId =
      payload.inventory.manufacturerBrand &&
      payload.inventory.manufacturerBrand.label === constants.OTHER
        ? constants.OTHER_ID
        : parseInt(payload.inventory.manufacturerBrand.value, 10);
    delete payload.inventory.manufacturerBrand;
    payload.inventory.unitManufacturerBaseColorId =
      payload.inventory.unitManufacturerBaseColor &&
      payload.inventory.unitManufacturerBaseColor.label !== constants.OTHER
        ? parseInt(payload.inventory.unitManufacturerBaseColor.value, 10)
        : null;

    payload.inventory.unitManufacturerBaseColorText =
      payload.inventory.unitManufacturerBaseColor &&
      payload.inventory.unitManufacturerBaseColor.label !== constants.OTHER
        ? payload.inventory.unitManufacturerBaseColor.label
        : payload.inventory.unitManufacturerBaseColorText;

    payload.inventory.unitManufacturerRoofColorId =
      payload.inventory.unitManufacturerRoofColor &&
      payload.inventory.unitManufacturerRoofColor.label !== constants.OTHER
        ? parseInt(payload.inventory.unitManufacturerRoofColor.value, 10)
        : null;

    payload.inventory.unitManufacturerRoofColorText =
      payload.inventory.unitManufacturerRoofColor &&
      payload.inventory.unitManufacturerRoofColor.label === constants.OTHER
        ? payload.inventory.unitManufacturerRoofColorText
        : payload.inventory.unitManufacturerRoofColor.label;

    payload.inventory.unitManufacturerTrimColorId =
      payload.inventory.unitManufacturerTrimColor &&
      payload.inventory.unitManufacturerTrimColor.label !== constants.OTHER
        ? parseInt(payload.inventory.unitManufacturerTrimColor.value, 10)
        : null;

    payload.inventory.unitManufacturerTrimColorText =
      payload.inventory.unitManufacturerTrimColor &&
      payload.inventory.unitManufacturerTrimColor.label !== constants.OTHER
        ? payload.inventory.unitManufacturerTrimColor.label
        : payload.inventory.unitManufacturerTrimColorText;

    payload.inventory.unitManufacturerSubProductId =
      payload.inventory.unitManufacturerSubProduct &&
      payload.inventory.unitManufacturerSubProduct.label !== constants.OTHER
        ? parseInt(payload.inventory.unitManufacturerSubProduct.value, 10)
        : constants.OTHER_ID;

    payload.inventory.unitManufacturerSubProductText =
      payload.inventory.unitManufacturerSubProduct &&
      payload.inventory.unitManufacturerSubProduct.label !== constants.OTHER
        ? payload.inventory.unitManufacturerSubProduct.label
        : payload.inventory.unitManufacturerSubProductText;

    payload.inventory.unitManufacturerSubProductSkuId =
      payload.inventory.unitManufacturerSubProductSku &&
      payload.inventory.unitManufacturerSubProductSku.label !== constants.OTHER
        ? payload.inventory.unitManufacturerSubProductSku.value
        : payload.inventory.unitManufacturerSubProductSkuId &&
          payload.inventory.unitManufacturerSubProductSkuId > 0
        ? payload.inventory.unitManufacturerSubProductSkuId
        : null;
    payload.inventory.unitTypeId =
      payload.inventory.unitType && payload.inventory.unitType.value
        ? payload.inventory.unitType.value
        : hideUnitOptionsFeature
        ? defaultMfgUnitTypeId
          ? defaultMfgUnitTypeId
          : constants.OTHER_ID
        : null;

    payload.inventory.unitWidthFeet = payload.inventory.unitWidthFeet
      ? payload.inventory.unitWidthFeet
      : parseInt(wValue, 10);
    payload.inventory.unitLengthFeet = payload.inventory.unitLengthFeet
      ? payload.inventory.unitLengthFeet
      : parseInt(lValue, 10);
    payload.inventory.unitHeightFeet = payload.inventory.unitHeightFeet
      ? payload.inventory.unitHeightFeet
      : parseInt(hValue, 10);

    payload.inventory.unitWidthFeetOtherText = wOtherTxt;
    payload.inventory.unitHeightFeetOtherText = hOtherTxt;
    payload.inventory.unitLengthFeetOtherText = lOtherTxt;

    payload.inventory.manufacturerId = payload.inventory.manufacturerBrandId;
    delete payload.inventory.manufacturer;
    // payload.inventory.bundlesToDelete
    // payload.addOnDto = inventory.unitOptionsList
    //payload.inventory.contractId = payload.inventory.contract
    //    ? parseInt(payload.inventory.contract.value, 10)
    //    : null;
    payload.quantity = payload.inventory.qty ? parseInt(payload.inventory.qty, 10) : null;
    if (!payload.inventory.wholesaleDiscountPercent) {
      payload.inventory.wholesaleDiscountPercent = 0.0;
    }
    if (repo && !_.isEmpty(repo)) {
      payload.InventoryRepo = {
        commission: parseFloat(repo.commission),
        deliveredOn: repo.deliveredOn,
        id: repo.id || 0,
        modelNumber: repo.modelNumber,
        originalCustomerFirstName: repo.originalCustomerFirstName,
        originalCustomerLastName: repo.originalCustomerLastName,
        originalCustomerLicenseNumber: repo.originalCustomerLicenseNumber,
        originalCustomerSSN: repo.originalCustomerSSN,
        originalDealerId: repo.originalDealer ? repo.originalDealer.value : null,
        originalDealerOtherText: repo.originalDealerOtherText,
        originalPrice: repo.originalPrice,
        originalSalespersonName: repo.originalSalespersonName,
        otherRevenue: repo.otherRevenue,
        pickupFee: repo.pickupFee,
        redeliveryFee: repo.redeliveryFee,
        refurbFee: repo.refurbFee,
        rentalRevenue: repo.rentalRevenue,
        repoOn: repo.repoOn,
        unitInventoryId: repo.unitInventoryId || inventory.id,
      };
    }
    api
      .post("inventory/save", payload)
      .then((r) => {
        if (!r.data || !r.data.success) {
          Alert.error(r.data.message || "There was an issue while saving this inventory item");
          return;
        }
        const isMulti = payload.quantity > 1;
        // multiple save returns an int array, a single returns the new inventory object
        const newIdList = isMulti ? r.data.message : [r.data.message.id];
        // save any unsaved comments
        let commentSavePosts = [];
        _.each(newIdList, (newId) => {
          _.each(comments, (c) => {
            if (!c.id) {
              commentSavePosts.push(
                api
                  .post("Inventory/AddComment", {
                    id: newId,
                    comment: c.dsc,
                    isPrivate: c.isPrivate,
                  })
                  .then((r) => {
                    if (!r.data.success) {
                      console.error("failed to save a comment:", r.data.message);
                    }
                  })
              );
            }
          });
        });
        Promise.all(commentSavePosts).then((_results) => {
          if (hasBuildFeature && inventory.unitIsNew && resolvedInventoryId === 0) {
            setMultiIdList(newIdList);
            onShowEditor(newIdList);
          } else if (!inventory.unitIsNew && newIdList.length === 1 && resolvedInventoryId === 0) {
            setResolvedInventoryId(newIdList[0]);
            getInventoryData(newIdList[0]); // reload
            setWholesalePriceIsStale(true);
            setActiveTab(TABS.USED_BUILDING_DETAILS);
          } else {
            setRedirectTo(`/inventory/view/${newIdList[0]}`);
          }
        });
      })
      .catch((err) => {
        console.error(err);
        Alert.error("An error occurred. Inventory cannot be saved at this time.");
      });
  }

  function mapAddOnToUnitOption() {
    if (!unitSelectionState) return;
    const other = unitSelectionState.value === -10;
    const u = unitSelectionState;
    const defaultLength =
      u.typeOfUnitOfMeasure === constants.UNITS_OF_MEASURE_ID.LINEAR_FOOT && !(u.addOnLength > 0)
        ? 1
        : 0;
    let addOn = {
      id: 0,
      addOnId: u.addOnId ?? u.id,
      price: parseFloat(unitOptionPrice),
      name: other ? otherAddOnName : u.name,
      description: u.description ?? "",
      seq: -1,
      bundleId: null,
      showInFloorPlan: true,
      hasDimensions: true,
      length: u.addOnLength ?? defaultLength,
      width: u.addOnWidth ?? 0,
      swingTypeId: u.typeOfSwing || 0,
    };
    return addOn;
  }

  function addUnitOption() {
    if (unitSelectionState.label === constants.OTHER && !otherAddOnName) {
      Alert.warning("You must give the Add-On a name");
      return;
    }
    const newAddOn = mapAddOnToUnitOption();
    let inv = _.cloneDeep(inventory);
    let list = inv.unitOptionsList.slice();
    list.push(newAddOn);
    if (resolvedInventoryId) {
      updateInventoryAddons(list);
    } else {
      setInventory({
        ...inventory,
        unitOptionsList: list,
        retailPrice: inventory.retailPrice, //parseFloat(inventory.retailPrice) + parseFloat(newAddOn.price)
      });
      onMaybeRecalcWholesalePrice();
    }
    setUnitSelectionState(null);
    setUnitOptionPrice(0.0);
  }

  function deleteUnitOption(index) {
    const newInventory = _.cloneDeep(inventory);
    const removed = newInventory.unitOptionsList.splice(index, 1);
    if (resolvedInventoryId) {
      updateInventoryAddons(_.reject(newInventory.unitOptionsList, (r) => r === removed));
    } else {
      setInventory(newInventory);
      onMaybeRecalcWholesalePrice();
    }
  }

  function addComment() {
    let commentList = comments.slice();
    let newComment = Object.assign({}, comment);
    newComment.userId = currentUser.id;
    commentList.push(newComment);
    setComments(commentList);
    closeComment();
  }

  function randomInt() {
    const num = Math.floor(Math.random() * 1000);
    return num;
  }

  function setUnitSelect(addOn) {
    setUnitSelectionState(addOn);
    setUnitOptionPrice(addOn.addOnStandardPrice ?? 0.0);
  }

  const onCommentChange = (property, value) => {
    let newComment = Object.assign({}, comment);
    newComment[property] = value;
    setComment(newComment);
  };

  const closeComment = () => {
    setShowCommentModal(false);
    setComment(commentTemplate);
  };

  if (redirectTo) {
    return <Redirect to={redirectTo} />;
  }

  const imageAttachments = _.filter(attachments, (a) =>
    constants.INVENTORY_IMAGE_TYPE_IDS.includes(a.typeOfAttachment)
  );

  const getUniqueValues = (array) => {
    if (!array) return;
    let result = array.reduce((uniqueItem, o) => {
      if (!uniqueItem.some((obj) => obj.label === o.label)) {
        uniqueItem.push(o);
      }
      return uniqueItem;
    }, []);
    return result;
  };

  function findUnpairedContracts() {
    if (searchText === "" || !searchText || searchText.length < 2) return;
    let payload = {
      searchText: searchText,
      page: contractList ? contractList.pageNumber : 1,
      pageSize: 3,
    };
    console.log("searching for unpaired contracts", payload);
    api
      .post(`Contract/GetUnpairedContracts`, payload)
      .then((res) => {
        if (res.data && res.data.success) {
          setContractList(res.data.message);
        }
      })
      .catch((err) => console.error(err))
      .finally(() => setShowSearchingUnpairedSpinner(false));
  }

  const onPairContract = (contract) => {
    let inv = _.cloneDeep(inventory);
    let updatedInv = {
      ...inv,
      contractHasBeenPaired: true,
      overrideSerial: true,
      serialNumber: contract.serialNumber,
      contractId: contract.contractId,
      companyId: inv.company ? inv.company.value : contract.companyId,
      companyName: inv.company ? inv.company.label : contract.companyName,
      company: inv.company
        ? inv.company
        : contract.companyId
        ? { value: contract.companyId, label: contract.companyName }
        : null,
      dealerId: contract.dealerId,
      dealerName: contract.dealerName,
      dealer: contract.dealerId ? { value: contract.dealerId, label: contract.dealerName } : null,
      manufacturerBrandId: contract.manufacturerId,
      manufacturerBrandName: contract.manufacturerBrandName,
      manufacturerBrand: contract.manufacturerId
        ? { value: contract.manufacturerId, label: contract.manufacturerName }
        : null,
      //manufacturerBrandOtherText: contract.manufacturerBrandOtherText,
      unitTypeId: contract.unitTypeId,
      unitTypeName: contract.unitTypeName,
      unitType: contract.unitTypeId
        ? { value: contract.unitTypeId, label: contract.unitTypeName }
        : null,
      unitTypeOtherText: contract.unitTypeOtherText,
      unitIsNew: contract.unitIsNew,
      retailPrice: contract.unitPrice,
      productTypeId: contract.productTypeId,
      productTypeName: contract.productTypeName,
      productTypeOtherText: contract.productTypeOtherText,
      productType: contract.productTypeId
        ? { value: contract.productTypeId, label: contract.productTypeName }
        : null,
      unitLengthFeet: contract.unitLengthFeet,
      unitWidthFeet: contract.unitWidthFeet,
      unitLengthFeetOtherText: contract.unitLengthFeetOtherText,
      unitWidthFeetOtherText: contract.unitWidthFeetOtherText,
      unitManufacturerSubProductId: contract.unitManufacturerSubproductId,
      unitManufacturerSubProductText: contract.unitManufacturerSubproductText,
      unitManufacturerSubProduct: contract.unitManufacturerSubproductId
        ? {
            value: contract.unitManufacturerSubproductId,
            label: contract.unitManufacturerSubproductText,
          }
        : null,
      unitManufacturerTrimColorText: contract.unitManufacturerTrimColorText,
      unitManufacturerBaseColorText: contract.unitManufacturerBaseColorText,
      unitManufacturerRoofColorText: contract.unitManufacturerRoofColorText,
      unitManufacturerSubProductSkuId: contract.unitManufacturerSubProductSkuId,
      unitManufacturerSubProductSkuName: contract.unitManufacturerSubProductSkuName,
      unitManufacturerSubProductSku: contract.unitManufacturerSubProductSkuId
        ? {
            value: contract.unitManufacturerSubProductSkuId,
            label: contract.unitManufacturerSubProductSkuName,
          }
        : null,
      unitOptionList: contract.contractAddOns,
      wholesalePrice:
        Number(contract.unitPrice) -
        Number(contract.unitPrice) * (Number(contract.mfgDefaultWholesalePercentage) / 100),
    };
    setSearchText("");
    setContractList([]);
    setContractPairingMode(false);
    setInventory(updatedInv);
    setResolutionNeeded(true);
    Alert.success("Contract has been paired");
  };

  const isOtherSKU =
    inventory &&
    inventory.unitManufacturerSubProductSku &&
    inventory.unitManufacturerSubProductSku.label === constants.OTHER;
  const enablePriceEntry = !hasBuildFeature || !inventory.unitIsNew || isOtherSKU;

  function onShowEditor(inventoryIdList = null) {
    const mfgId =
      inventory &&
      inventory.manufacturerBrand &&
      inventory.manufacturerBrand.label !== constants.OTHER
        ? inventory.manufacturerBrand.value
        : null;
    const skuId =
      inventory.unitManufacturerSubProductSku &&
      inventory.unitManufacturerSubProductSku.label !== constants.OTHER
        ? inventory.unitManufacturerSubProductSku.value
        : null;
    const id = inventoryIdList ? inventoryIdList[0] : inventory.id;
    const payload = {
      ManufacturerId: mfgId,
      SubProductSKUId: skuId,
      InventoryId: id,
      ActiveOnly: true,
    };
    api
      .post("Reference/GetMfgListForFloorPlan", payload)
      .then((res) => {
        if (res.data.success) {
          let inv = _.cloneDeep(inventory);
          setSelectedFPAddOns(res.data.message.selectedAddOnOptions);
          setMfgFPAddOnOptions(res.data.message.addonOptions);
          setBundleOptions(res.data.message.bundleOptions || []);
          setHasExistingFloorPlan(res.data.message.hasExistingFloorPlan);
          setOptionColors(
            ui_helpers.idNameToValueLabel(res.data.message.optionColors, null, false)
          );
          if (res.data.message.mfgSku) {
            const sku = res.data.message.mfgSku;
            inv.typeOfPorch = sku.porchTypeId;
            inv.basePrice = sku.basePrice;
            if (inventoryIdList && inventoryIdList.length) {
              inv.unitWidthFeet = sku.width;
              inv.unitLengthFeet = sku.length;
              inv.id = id;
              inv.porchMax = sku.porchMax;
              inv.porchMin = sku.porchMin;
              inv.typeOfPorch = sku.porchTypeId;
              inv.porchWall1Length = sku.porchWall1Length;
              inv.porchWall2Length = sku.porchWall2Length;
              inv.porchWall3Length = sku.porchWall3Length;
              inv.porchWall4Length = sku.porchWall4Length;
              inv.price = sku.basePrice;
            }
          } else if (res.data.message.inventory) {
            let newInv = res.data.message.inventory;
            inv.standardRetailPrice = newInv.standardRetailPrice;
            inv.standardSkuBasePrice = newInv.standardSkuBasePrice;
            inv.unitWidthFeet = newInv.resolvedWidthFeet;
            inv.unitLengthFeet = newInv.resolvedLengthFeet;
            inv.unitHeightFeet = newInv.resolvedHeightFeet;
            inv.basePrice = newInv.skuId ? newInv.basePrice : newInv.retailPrice;
            inv.typeOfPorch = newInv.porchTypeId;
          }
          inv.agreedPrice = res.data.message.agreedPrice;
          setInventory(inv);
        }
        setShowEditor(true);
      })
      .catch((err) => console.error(err));
  }

  const onFPSave = (exterior, selectedAddOns, rulerOffset, fontSize, imgUrl) => {
    floorplan_helpers.saveFloorPlanChanges(
      constants.FPContext.inventory,
      exterior,
      selectedAddOns,
      fontSize,
      rulerOffset,
      imgUrl,
      (e) => doRedirect(e),
      multiIdList
    );
  };

  const doRedirect = (firstId) => {
    if (firstId) {
      // setRedirectTo(`/BuildDetails/${firstId}`);
      setRedirectTo(`/inventory/view/${firstId}`);
    } else if (inventoryId > 0) {
      getInventoryData();
    }
  };

  function onCloseFloorPlan() {
    setShowEditor(false);
    getInventoryData();
  }

  const getSerialInputDisabledState = (inv) => {
    if (inv.id > 0) return inv.hasBuildFeature;
    return hasBuildFeature;
  };

  function resolveModelName() {
    return inventory.unitManufacturerSubProductSku &&
      inventory.unitManufacturerSubProductSku.value !== constants.OTHER_ID
      ? inventory.unitManufacturerSubProductSku.label
      : inventory.serialNumber;
  }

  function renderFloorplan() {
    /* this is when the launch layout editor */
    const exterior = floorplan_helpers.mapExteriorForFP(inventory, constants.FPContext.inventory);
    return (
      <FloorplanTool
        editingContext={constants.FPContext.inventory}
        exterior={exterior}
        optionColors={optionColors}
        addOnOpts={floorplan_helpers.mapAddonOptionsForFP(
          mfgFPAddOnOptions,
          constants.FPContext.inventory
        )}
        selectedAddOns={floorplan_helpers.mapSelectedAddonOptionsForFP(
          selectedFPAddOns,
          constants.FPContext.inventory,
          exterior.height
        )}
        saveCallback={onFPSave}
        rulerOffset={inventory.rulerOffSet || floorplan_helpers.defaultRulerOffset} //no inventory defined
        fontSize={inventory.fontSize || floorplan_helpers.defaultFontSize}
        onExit={onCloseFloorPlan}
        bundleOptions={floorplan_helpers.mapBundlesForFP(
          bundleOptions,
          constants.FPContext.inventory
        )}
        hideExitButton={hideFPExit}
        hasExistingFloorPlan={hasExistingFloorPlan}
        modelName={resolveModelName()}
      />
    );
  }

  return (
    <div>
      <Header />
      <div className="inner">
        <div className="inner-white">
          {showModelModal ? (
            <ModelModal
              modelNumber={modelNumber}
              changeModelNumber={changeModelNumber}
              setShowModelModal={setShowModelModal}
              setModelNumber={setModelNumber}
            />
          ) : null}
          {showCommentModal ? (
            <Modal fullscreen="sm" toggle={() => setShowCommentModal(false)} isOpen>
              <ModalHeader>Add Comment</ModalHeader>
              <ModalBody>
                <Input
                  type="textarea"
                  className="comment-textarea"
                  maxLength="800"
                  name="dsc"
                  value={comment.dsc}
                  onChange={(e) => onCommentChange(e.target.name, e.target.value)}
                />
                <div className="mb-1">
                  <Input
                    type="checkbox"
                    checked={comment.isPrivate}
                    onChange={(e) => {
                      e.stopPropagation();
                      onCommentChange("isPrivate", !comment.isPrivate);
                    }}
                  />{" "}
                  Private
                </div>
              </ModalBody>
              <ModalFooter>
                <Row className={"d-flex justify-content-end"}>
                  <Col>
                    <ButtonGroup className="float-end">
                      <Button color="primary" onClick={addComment}>
                        Save
                      </Button>
                      <Button onClick={closeComment}>Close</Button>
                    </ButtonGroup>
                  </Col>
                </Row>
              </ModalFooter>
            </Modal>
          ) : null}
          {pairingOptions && pairingOptions.length ? (
            <Modal size="lg" toggle={() => setPairingOptions([])} isOpen>
              <div className="modal-header pr-0">
                <Row style={{ width: "100%" }}>
                  <Col xs="9">
                    <h5>Matching Serial #s Detected</h5>
                  </Col>
                  <Col className="pr-0">
                    <button
                      className="close float-end btn btn-sm text-secondary pt-0"
                      style={{
                        fontSize: "1.4rem",
                        marginRight: "-36px",
                        marginTop: "-10px",
                      }}
                      datadismiss="modal"
                      onClick={() => setPairingOptions([])}
                    >
                      <FontAwesomeIcon icon="times-circle" />
                    </button>
                  </Col>
                </Row>
              </div>
              <ModalBody>
                <p>
                  The following were found with this same serial #. Select an existing item, if
                  appropriate, to prefill this inventory item's values.
                </p>
                {_.some(pairingOptions, (po) => po.isInventory) ? (
                  <p style={{ fontWeight: "bold", color: "#ff0000" }}>
                    You should choose the existing inventory item if your wish is to set the
                    inventory status back to "In Stock" and resell the unit.
                  </p>
                ) : null}
                {!_.some(pairingOptions, (po) => po.isInventory) &&
                _.some(pairingOptions, (po) => !po.isInventory) ? (
                  <p>
                    Click "Apply" to apply the matching contract's unit details to the inventory
                    item you are entering.
                  </p>
                ) : null}
                <Table>
                  <thead>
                    <tr>
                      <th>Type</th>
                      <th>Manufacturer</th>
                      <th>Cntr #</th>
                      <th>Inv #</th>
                      <th>Inv Status</th>
                      <th>Cntr Status</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {_.map(pairingOptions, (po) => (
                      <tr key={`po-${po.inventoryId}-${po.contractId}`}>
                        <td>
                          {po.isInventory ? (
                            <span className="badge bg-dark">Inventory</span>
                          ) : (
                            <span className="badge bg-info">Contract</span>
                          )}
                        </td>
                        <td>{po.manufacturerName}</td>
                        <td>
                          {po.isInventory && po.contractId
                            ? `Associated to ${po.contractId}`
                            : po.contractId
                            ? po.contractId
                            : "-"}
                        </td>
                        <td>{po.isInventory ? po.invoiceNumber : "N/A"}</td>
                        <td>
                          {po.isInventory ? (
                            <InventoryStatusBadge inventoryStatus={po.inventoryStatus} />
                          ) : (
                            <span>N/A</span>
                          )}
                        </td>
                        <td>
                          {po.contractStatus ? (
                            ui_helpers.renderContractStatusBadge(po.contractStatus)
                          ) : (
                            <span>N/A</span>
                          )}
                        </td>
                        <td>
                          <Button
                            color="primary"
                            size="sm"
                            className="float-end"
                            onClick={() => confirmPairing(po)}
                          >
                            Apply
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </ModalBody>
            </Modal>
          ) : null}
          <Card className="m-2 p-4 bg-light">
            <TabNavigator
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              hasRepo={repo !== null}
              inventoryId={inventory && inventory.id ? inventory.id : 0}
              canEditBuild={inventory && inventory.id && hasBuildFeature}
              isUsed={resolvedInventoryId && resolvedInventoryId > 0 && !inventory.unitIsNew}
            />
            <TabContent activeTab={activeTab}>
              <TabPane tabId={TABS.INVENTORY_DETAILS}>
                {isLoading || isLoadingFilter ? (
                  <Loading />
                ) : (
                  <div className="p-2">
                    <Form>
                      <Row>
                        {/**************************************************************
                         *
                         *  COLUMN 1
                         *
                         **************************************************************/}
                        <Col>
                          <FormGroup>
                            <Label>
                              Pair To Contract?
                              <ToggleButton
                                value={contractPairingMode}
                                activeLabel={"Yes"}
                                inactiveLabel={"No"}
                                colors={constants.TOGGLE_BUTTON_COLORS}
                                onToggle={(e) => {
                                  if (e === true) {
                                    setSearchText("");
                                    setContractList([]);
                                  }
                                  setContractPairingMode(!e);
                                }}
                              />
                            </Label>
                          </FormGroup>
                          {contractPairingMode && (
                            <Fragment>
                              {showSearchingUnpairedSpinner ? (
                                <Spinner size="sm" />
                              ) : (
                                <div>
                                  <FormGroup>
                                    <Label>
                                      Search Contracts{" "}
                                      <FontAwesomeIcon icon="info-circle" id="cntSearchInfo" />:
                                      {/*<Input type="text"*/}
                                      {/*    placeholder={"Type details to search..."}*/}
                                      {/*    value={searchText}*/}
                                      {/*    onChange={(e) => { setSearchText(e.target.value); findUnpairedContracts(e.target.value) }}*/}
                                      {/*/>*/}
                                      <UncontrolledTooltip placement="right" target="cntSearchInfo">
                                        Search by Customer name, delivery address, serial number,
                                        unit colors (base/trim/roof), manufacturer name...etc.
                                      </UncontrolledTooltip>
                                    </Label>
                                    <DebounceInput
                                      type="text"
                                      min={3}
                                      className="form-control"
                                      debounceTimeout={300}
                                      placeholder="Type at least 3 characters to search"
                                      value={searchText}
                                      onChange={(e) => {
                                        setSearchText(e.target.value);
                                      }}
                                    />
                                    {searchText && contractList && (
                                      <>
                                        <Table>
                                          <thead>
                                              <th>Results</th>
                                              <th />
                                          </thead>
                                          <tbody>
                                            {contractList.items && contractList.items.length > 0 ? (
                                              contractList.items.map((c) => (
                                                <tr>
                                                  <td>
                                                    <div>
                                                      <span className="boldData">
                                                        Customer Name:
                                                      </span>{" "}
                                                      {c.customerFirstName} {c.customerLastName}
                                                    </div>
                                                    <div className="small-text">
                                                      <span className="boldData">
                                                        Delivery Address
                                                      </span>
                                                      :
                                                      {c.deliveryAddress1
                                                        ? c.deliveryAddress1 + " "
                                                        : ""}
                                                      {c.deliveryAddress2
                                                        ? c.deliveryAddress2 + " "
                                                        : ""}
                                                      {c.deliveryCity ? c.deliveryCity + " " : ""}
                                                      {c.deliveryState ? c.deliveryState + " " : ""}
                                                      {c.deliveryZip ? c.deliveryZip : ""}
                                                    </div>
                                                    <div className="small-text">
                                                      <span className="boldData">Product Type</span>
                                                      : {c.productTypeName} |{" "}
                                                      <span className="boldData">Style:</span>{" "}
                                                      {c.unitManufacturerSubproductText}
                                                    </div>
                                                    <div className="small-text">
                                                      <span className="boldData">Base:</span>{" "}
                                                      {c.unitManufacturerBaseColorText} |{" "}
                                                      <span className="boldData">Roof:</span>{" "}
                                                      {c.unitManufacturerRoofColorText} |{" "}
                                                      <span className="boldData">Trim:</span>{" "}
                                                      {c.unitManufacturerTrimColorText}
                                                    </div>
                                                    <div className="small-text">
                                                      <span className="boldData">
                                                        Manufacturer:
                                                      </span>{" "}
                                                      {c.manufacturerName}
                                                    </div>
                                                    <div className="small-text">
                                                      <span className="boldData">Dealer:</span>{" "}
                                                      {c.dealerName}
                                                    </div>
                                                    <div className="small-text">
                                                      <span className="boldData">
                                                        Serial Number:
                                                      </span>{" "}
                                                      {c.serialNumber}
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <Button
                                                      size="sm"
                                                      color="primary"
                                                      onClick={() => onPairContract(c)}
                                                    >
                                                      Select
                                                    </Button>
                                                  </td>
                                                </tr>
                                              ))
                                            ) : (
                                                <td>No Contract Records Found</td>
                                            )}
                                          </tbody>
                                        </Table>
                                        <Pager
                                          pageNumber={contractList ? contractList.pageNumber : 1}
                                          pageSize={contractList ? contractList.pageSize : 0}
                                          totalResults={contractList ? contractList.totalCount : 0}
                                          onSetPageCallback={(e) =>
                                            setContractList({ ...contractList, pageNumber: e })
                                          }
                                        />
                                      </>
                                    )}
                                  </FormGroup>
                                </div>
                              )}
                            </Fragment>
                          )}
                          <CFFormGroup
                            required
                            label="Manufacturer (brand)"
                            name="manufacturerBrandId"
                            type="select"
                            value={inventory.manufacturerBrand}
                            // selected={inventory.manufacturerBrand}
                            onChange={(e) => onInventorySelectChange(e, "manufacturerBrand")}
                            isDisabled={inventory.pairedContractAgreedPrice ? true : false}
                            options={mfgOptions}
                          />
                          {inventory.manufacturerBrand &&
                          inventory.manufacturerBrand.label === constants.OTHER ? (
                            <Input
                              placeholder="Other Manufacturer Name"
                              label="Other Manufacturer Name"
                              name="manufacturerBrandName"
                              type="text"
                              maxLength={100}
                              value={inventory.manufacturerBrandName}
                              onChange={onInventoryChange}
                            />
                          ) : null}
                          <FormGroup tag="fieldset">
                            <FormGroup check inline>
                              <Input
                                type="radio"
                                name="unitIsNew"
                                checked={inventory.unitIsNew}
                                onChange={(e) =>
                                  handleInventoryChange(e.target.name, e.target.checked)
                                }
                              />
                              <Label check>New</Label>
                            </FormGroup>
                            <FormGroup check inline>
                              <Input
                                type="radio"
                                name="unitIsNew"
                                checked={!inventory.unitIsNew}
                                onChange={(e) =>
                                  handleInventoryChange(e.target.name, !e.target.checked)
                                }
                              />
                              <Label check>Used</Label>
                            </FormGroup>
                          </FormGroup>
                          <FormGroup>
                            <CFLabel label="Serial #" required />
                            <Input
                              type="text"
                              name="serialNumber"
                              value={inventory.serialNumber}
                              onChange={onInventoryChange}
                              onBlur={onBlur}
                              maxLength="40"
                              disabled={
                                hasBuildFeature &&
                                inventory.unitIsNew &&
                                mfgCustomSerialFeature &&
                                !inventory.overrideSerial
                              }
                              title={
                                hasBuildFeature && mfgCustomSerialFeature
                                  ? "Serial Number Generation Feature Active"
                                  : ""
                              }
                            />
                            {mfgCustomSerialFeature ? (
                              <div>
                                <Input
                                  type="checkbox"
                                  name="overrideSerial"
                                  className="float-start"
                                  value={inventory.overrideSerial}
                                  checked={inventory.overrideSerial}
                                  onChange={() =>
                                    handleInventoryChange(
                                      "overrideSerial",
                                      !inventory.overrideSerial
                                    )
                                  }
                                />
                                <div className="ps-4">Override Serial #</div>
                              </div>
                            ) : null}
                          </FormGroup>
                          <CFFormGroup
                            required
                            label="Owned By"
                            name="companyId"
                            type="select"
                            value={inventory.company}
                            onChange={(e) => onInventorySelectChange(e, "company")}
                            options={getUniqueValues(companyOptions)}
                          />
                          <CFFormGroup
                            required
                            label="Dealer (Lot)"
                            name="dealerId"
                            type="select"
                            placeholder=""
                            value={inventory.dealer}
                            onChange={(e) => onInventorySelectChange(e, "dealer")}
                            options={dealerOptions}
                          />
                          <CFFormGroup
                            required
                            label="Inventory Start Date"
                            name="inventoryOn"
                            type="date"
                            value={inventory.inventoryOn}
                            onChange={onInventoryOnDateChange}
                          />
                        </Col>

                        {/**************************************************************
                         *
                         *  COLUMN 2
                         *
                         **************************************************************/}
                        <Col className="form-col-even">
                          <CFFormGroup
                            required
                            label="Product Type"
                            name="productTypeId"
                            type="select"
                            options={productTypes ? productTypes : constants.PRODUCT_TYPES}
                            value={inventory.productType}
                            isDisabled={inventory.pairedContractAgreedPrice ? true : false}
                            onChange={(e) => onInventorySelectChange(e, "productType")}
                          />
                          {inventory.productType &&
                          inventory.productType.label === constants.OTHER ? (
                            <Input
                              placeholder="Product-Type-Description"
                              label="Product Type Text"
                              name="productTypeOtherText"
                              type="text"
                              value={inventory.productTypeOtherText}
                              onChange={onInventoryChange}
                            />
                          ) : null}
                          <CFFormGroup
                            required
                            label="Style"
                            name="unitManufacturerSubProductId"
                            type="select"
                            value={inventory.unitManufacturerSubProduct}
                            onChange={(e) =>
                              onInventorySelectChange(e, "unitManufacturerSubProduct")
                            }
                            options={subProductOptions}
                            isDisabled={
                              !inventory.productType || inventory.pairedContractAgreedPrice
                            }
                            placeholder={
                              subProductOptions.length
                                ? "- Select Style -"
                                : "- Select Manufacturer -"
                            }
                          />
                          {inventory.unitManufacturerSubProduct &&
                          inventory.unitManufacturerSubProduct.label === constants.OTHER ? (
                            <Input
                              placeholder="Product-Description"
                              label="Product Text"
                              name="unitManufacturerSubProductText"
                              type="text"
                              value={inventory.unitManufacturerSubProductText}
                              onChange={onInventoryChange}
                            />
                          ) : null}
                          {inventory.unitManufacturerSubProduct ? (
                            <Row>
                              <Col xs={{ size: 7, offset: 1 }}>
                                <CFFormGroup
                                  required
                                  label="SKU / Size"
                                  classNames="mb-1"
                                  name="unitManufacturerSubProductSku"
                                  type="select"
                                  value={inventory.unitManufacturerSubProductSku}
                                  onChange={(e) =>
                                    onInventorySelectChange(e, "unitManufacturerSubProductSku")
                                  }
                                  options={productSkuOptions}
                                  isDisabled={
                                    !inventory.unitManufacturerSubProduct ||
                                    !productSkuOptions.length ||
                                    inventory.pairedContractAgreedPrice
                                  }
                                />
                              </Col>
                            </Row>
                          ) : null}
                          {inventory.unitManufacturerSubProductSku &&
                          inventory.unitManufacturerSubProductSku.label === constants.OTHER ? (
                            <Row>
                              <Col xs={{ size: 7, offset: 1 }}>
                                <FormGroup className="size-form-group mb-1">
                                  <WLHInput
                                    otherTextOnly
                                    wOtherTxt={wOtherTxt}
                                    lOtherTxt={lOtherTxt}
                                    hOtherTxt={hOtherTxt}
                                    setWOtherTxt={setWOtherTxt}
                                    setLOtherTxt={setLOtherTxt}
                                    setHOtherTxt={setHOtherTxt}
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                          ) : null}
                          {hideUnitOptionsFeature ? null : (
                            <>
                              <CFFormGroup
                                required
                                label="Material"
                                name="unitTypeId"
                                classNames="mb-1"
                                type="select"
                                value={inventory.unitType}
                                onChange={(e) => onInventorySelectChange(e, "unitType")}
                                options={unitTypeOptions}
                                isDisabled={!inventory.productType}
                              />
                              {inventory.unitType &&
                              inventory.unitType.label === constants.OTHER ? (
                                <Input
                                  placeholder="Material Description"
                                  label="Material Text"
                                  name="unitTypeOtherText"
                                  type="text"
                                  value={inventory.unitTypeOtherText}
                                  onChange={onInventoryChange}
                                />
                              ) : null}
                            </>
                          )}
                          <CFFormGroup
                            required
                            label="Base Color"
                            name="unitManufacturerBaseColorId"
                            classNames="mb-1"
                            type="select"
                            value={inventory.unitManufacturerBaseColor}
                            onChange={(e) =>
                              onInventorySelectChange(e, "unitManufacturerBaseColor")
                            }
                            isDisabled={!baseColorOptions.length}
                            options={baseColorOptions}
                            placeholder={
                              baseColorOptions.length
                                ? "- Select Base Color -"
                                : "- Select Manufacturer -"
                            }
                          />
                          {inventory.unitManufacturerBaseColor &&
                          inventory.unitManufacturerBaseColor.label === constants.OTHER ? (
                            <Input
                              placeholder="Base-Color-Description"
                              label="Base Color"
                              name="unitManufacturerBaseColorText"
                              type="text"
                              value={inventory.unitManufacturerBaseColorText}
                              onChange={onInventoryChange}
                            />
                          ) : null}
                          <CFFormGroup
                            required
                            label="Trim Color"
                            name="unitManufacturerTrimColorId"
                            type="select"
                            classNames="mb-1"
                            value={inventory.unitManufacturerTrimColor}
                            onChange={(e) =>
                              onInventorySelectChange(e, "unitManufacturerTrimColor")
                            }
                            isDisabled={!trimColorOptions.length}
                            options={trimColorOptions}
                            placeholder={
                              trimColorOptions.length
                                ? "- Select Trim Color -"
                                : "- Select Manufacturer -"
                            }
                          />
                          {inventory.unitManufacturerTrimColor &&
                          inventory.unitManufacturerTrimColor.label === constants.OTHER ? (
                            <Input
                              placeholder="Trim-Color-Description"
                              label="Trim Color"
                              name="unitManufacturerTrimColorText"
                              type="text"
                              value={inventory.unitManufacturerTrimColorText}
                              onChange={onInventoryChange}
                            />
                          ) : null}
                          <CFFormGroup
                            required
                            label="Roof Color"
                            name="unitManufacturerRoofColorId"
                            type="select"
                            classNames="mb-1"
                            value={inventory.unitManufacturerRoofColor}
                            onChange={(e) =>
                              onInventorySelectChange(e, "unitManufacturerRoofColor")
                            }
                            isDisabled={!roofColorOptions.length}
                            options={roofColorOptions}
                            placeholder={
                              roofColorOptions.length
                                ? "- Select Roof Color -"
                                : "- Select Manufacturer -"
                            }
                          />
                          {inventory.unitManufacturerRoofColor &&
                          inventory.unitManufacturerRoofColor.label === constants.OTHER ? (
                            <Input
                              placeholder="Roof-Color-Description"
                              label="Roof Color"
                              name="unitManufacturerRoofColorText"
                              type="text"
                              value={inventory.unitManufacturerRoofColorText}
                              onChange={onInventoryChange}
                              className="mb-2"
                            />
                          ) : null}
                        </Col>
                        {/**************************************************************
                         *
                         *  COLUMN 3
                         *
                         **************************************************************/}
                        <Col>
                          <CFFormGroup required label="Retail Price" labelOnly>
                            <CurrencyInput
                              name="retailPrice"
                              size="sm"
                              className="form-control"
                              decimalsLimit={2}
                              allowNegativeValue={false}
                              prefix={"$"}
                              min={0}
                              value={inventory.retailPrice}
                              onValueChange={(value, name) => {
                                handleInventoryChange(name, value);
                              }}
                              disabled={!enablePriceEntry}
                              title={hasBuildFeature ? "Build feature active" : ""}
                              onBlur={onMaybeRecalcWholesalePrice}
                            />
                          </CFFormGroup>
                          {hideWholesaleFields ? null : (
                            <Fragment>
                              <CFFormGroup
                                label="Wholesale Discount %"
                                name="wholesaleDiscountPercent"
                                value={inventory.wholesaleDiscountPercent}
                                onBlur={onMaybeRecalcWholesalePrice}
                                onChange={onInventoryChange}
                              />
                              <CFFormGroup required label="Wholesale Price" labelOnly>
                                <CurrencyInput
                                  name="wholesalePrice"
                                  size="sm"
                                  className="form-control"
                                  decimalsLimit={2}
                                  min={0}
                                  prefix={"$"}
                                  allowNegativeValue={false}
                                  value={inventory.wholesalePrice}
                                  onValueChange={(value, name) => {
                                    handleInventoryChange(name, value);
                                  }}
                                />
                              </CFFormGroup>
                            </Fragment>
                          )}
                          <CFFormGroup required label="Invoice #" labelOnly>
                            <InputGroup>
                              <Input
                                type="text"
                                name="invoiceNumber"
                                value={inventory.invoiceNumber}
                                onChange={onInventoryChange}
                              />
                              <Button
                                color="success"
                                className="text-light"
                                onClick={() => getNextInvoiceNumber()}
                              >
                                <FontAwesomeIcon icon="sync" />
                              </Button>
                            </InputGroup>
                          </CFFormGroup>
                          {!inventory.id || inventory <= 0 ? (
                            <>
                              <CFFormGroup
                                label="Quantity"
                                name="qty"
                                type="text"
                                title={
                                  !mfgCustomSerialFeature
                                    ? "Manufacture Custom Serial Feature Required"
                                    : ""
                                }
                                value={inventory.qty}
                                onChange={onInventoryChange}
                                disabled={!mfgCustomSerialFeature}
                              />
                              {/*<CFFormGroup
                                                      label="Pair To Contract"
                                                      name="contract"
                                                      type="select"
                                                      options={contractList}
                                                      value={inventory.contract}
                                                      onChange={(e)=>onInventorySelectChange(e, "contract")}
                                                      isDisabled={!inventory.dealer || !contractList.length}
                                                      isClearable
                                                  />*/}
                            </>
                          ) : null}
                          {resolvedInventoryId === 0 ? null : (
                            <div>
                              {inventory.checkNo ? (
                                <CFFormGroup
                                  label={
                                    inventory.paidOn &&
                                    date_helpers.formatDateForServer(inventory.paidOn) !==
                                      constants.NULL_PAYMENT_DATE
                                      ? `Paid ${date_helpers.formatDateToShortDate(
                                          inventory.paidOn
                                        )}, Check #`
                                      : null
                                  }
                                  name="checkNo"
                                  value={inventory.checkNo}
                                  disabled
                                />
                              ) : null}
                              <FormGroup>
                                <CFLabel label="RTO ID" />
                                <Row className="align-items-center">
                                  <Col sm="8">{inventory.modelNumber}</Col>
                                  {canEdit ? (
                                    <Col>
                                      <Button
                                        size="sm"
                                        color="warning"
                                        className="float-end"
                                        onClick={() => {
                                          setModelNumber(inventory.modelNumber);
                                          setShowModelModal(true);
                                        }}
                                      >
                                        Set
                                      </Button>
                                    </Col>
                                  ) : null}
                                </Row>
                              </FormGroup>
                              <CFFormGroup
                                required
                                label="Contract #"
                                name="contractId"
                                value={inventory.contractId}
                                disabled={true}
                                paired={inventory.contractHasBeenPaired}
                              />
                            </div>
                          )}
                          {hasBuildFeature ? (
                            <CFFormGroup
                              label="Order Note"
                              name="orderNote"
                              type="textarea"
                              value={inventory.orderNote}
                              onChange={onInventoryChange}
                              //bsSize={"lg"}
                              maxLength={100}
                            />
                          ) : null}
                        </Col>
                        {/* COLUMN 4 */}
                        <Col className="form-col-even">
                          {hasBuildFeature ? (
                            <Row>
                              <Col className="p-3">
                                <AddOnAdvice text="Add-Ons will be added in the 'Layout Editor'" />
                              </Col>
                            </Row>
                          ) : (
                            <Fragment>
                              <Row className="pe-2">
                                <Col>
                                  <BundleInfo
                                    selectedSku={inventory.unitManufacturerSubProductSku}
                                    manufacturer={inventory.manufacturerBrand}
                                    onChange={setInventory}
                                    data={inventory}
                                    bundleContext={constants.BUNDLE_CONTEXT.inventory}
                                    updateAddons={updateInventoryAddons}
                                  />
                                </Col>
                              </Row>
                              <Row>
                                <Col xs={6}>
                                  <CFLabel label="Add-Ons" required={false} />
                                  <Select
                                    value={unitSelectionState}
                                    options={addOns}
                                    onChange={setUnitSelect}
                                    isDisabled={!addOns || !addOns.length}
                                  />
                                </Col>
                                <Col xs={3} style={{ padding: "0" }}>
                                  <Label>{""}</Label>
                                  <CurrencyInput
                                    style={{
                                      padding: "4px 36px 4px 8px",
                                      height: "32px",
                                    }}
                                    type="text"
                                    placeholder="Price"
                                    min={0}
                                    disabled={!unitSelectionState}
                                    className="form-control"
                                    allowNegativeValue={false}
                                    onValueChange={(value, name) =>
                                      setUnitOptionPrice(parseFloat(value))
                                    }
                                    value={isNaN(unitOptionPrice) ? 0.0 : unitOptionPrice}
                                    prefix="$"
                                  />
                                </Col>
                                <Col xs={3}>
                                  <div className="d-flex justify-content-end">
                                    <Button
                                      style={{
                                        margin: "25px 0px 0px 0px",
                                        height: "30px",
                                      }}
                                      size="sm"
                                      color="success"
                                      className="text-light"
                                      disabled={!unitSelectionState}
                                      onClick={addUnitOption}
                                    >
                                      <FontAwesomeIcon icon="plus-circle" className="me-2" />
                                      Add
                                    </Button>
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col xs={6}>
                                  {unitSelectionState &&
                                  (unitSelectionState.value === constants.OTHER_ID ||
                                    unitSelectionState.id === constants.OTHER_ID) ? (
                                    <div className="control-group">
                                      <Input
                                        type="text"
                                        bsSize="sm"
                                        className="mt-1"
                                        maxLength="150"
                                        placeholder="Other Details"
                                        value={otherAddOnName}
                                        onChange={(e) => setOtherAddOnName(e.target.value)}
                                      />
                                    </div>
                                  ) : null}
                                </Col>
                              </Row>
                              <Row className="mt-1">
                                <Col>
                                  <Table>
                                    <tbody>
                                      {inventory &&
                                      inventory.unitOptionsList &&
                                      inventory.unitOptionsList.length
                                        ? _.map(inventory.unitOptionsList, (u, idx) => (
                                            <tr key={`uo${idx}-${u.id}`}>
                                              <td>{u.name}</td>
                                              <td colSpan={2}>
                                                {u.bundleId ? (
                                                  <span className="text-muted">Bundled Item</span>
                                                ) : (
                                                  ui_helpers.formatCurrency(
                                                    parseFloat(u.price || 0.0)
                                                  )
                                                )}
                                                {u.typeOfUnitOfMeasure &&
                                                  (u.typeOfUnitOfMeasure ===
                                                  constants.UNITS_OF_MEASURE_ID.EACH
                                                    ? " ea."
                                                    : " /ft")}
                                              </td>
                                              <td>
                                                {u.bundleId ? null : (
                                                  <Button
                                                    size={"sm"}
                                                    color="danger"
                                                    className={"text-white float-end"}
                                                    onClick={() => deleteUnitOption(idx)}
                                                  >
                                                    <FontAwesomeIcon icon="times-circle" />
                                                  </Button>
                                                )}
                                              </td>
                                            </tr>
                                          ))
                                        : null}
                                    </tbody>
                                  </Table>
                                </Col>
                              </Row>
                            </Fragment>
                          )}
                        </Col>
                      </Row>
                    </Form>
                  </div>
                )}
              </TabPane>
              <TabPane tabId={TABS.USED_BUILDING_DETAILS}>
                <div className="p-2">
                  <UsedBuildingDetails
                    inventory={inventory}
                    repoData={repo}
                    setRepoData={setRepo}
                    dealerList={dealerOptions}
                    hasUnprocessedContract={hasUnprocessedContract}
                    pl={pl}
                    contractSalePrice={inventory?.contractSalePrice}
                  />
                </div>
              </TabPane>
              <TabPane tabId={TABS.INVENTORY_IMAGES}>
                <div className="p-2">
                  <Table bordered>
                    <tbody>
                      {imageAttachments && imageAttachments.length ? (
                        _.map(imageAttachments, (a) => {
                          return (
                            <tr key={`a-${a.id}`}>
                              <td>
                                <Row>
                                  <Col>
                                    <a href="#" onClick={(e) => onDownloadAttachment(e, a.key)}>
                                      {a.name}
                                    </a>
                                  </Col>
                                  <Col className="col-4">
                                    <Row>
                                      <Col>{`${date_helpers.dateTimeFormat(a.createdAt)}`}</Col>
                                      <Col>{`${a.createdByUserFirstName} ${a.createdByUserLastName}`}</Col>
                                    </Row>
                                  </Col>
                                  <Col className="col-1">
                                    <Button
                                      color="danger"
                                      size="sm"
                                      className="text-white float-end"
                                      onClick={() => onDeleteAttachment(a)}
                                    >
                                      <FontAwesomeIcon icon="times-circle" />
                                    </Button>
                                  </Col>
                                </Row>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td>There are no attachments for this item.</td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </div>
              </TabPane>
              <TabPane tabId={TABS.COMMENTS}>
                <Row className={"my-2 d-flex justify-content-end"}>
                  <Col>
                    <Button
                      onClick={() => setShowCommentModal(true)}
                      size="sm"
                      className={"bg-cf-success border-success"}
                    >
                      <FontAwesomeIcon icon={"plus"} className={"me-1"} />
                      Add Comment
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <Table>
                    <thead></thead>
                    <tbody>
                      {comments && comments.length ? (
                        _.map(comments, (c) => (
                          <tr
                            key={c.id}
                            className={classnames({ private: c.isPrivate }, "comment-row")}
                          >
                            <td className="content">{c.dsc}</td>
                            <td style={{ width: "180px" }}>{c.createdAt}</td>
                            <td style={{ width: "200px" }}>{c.userFullName}</td>
                            <td style={{ width: "50px" }}>
                              <Button
                                color="danger"
                                className="text-light"
                                size="sm"
                                title="Delete"
                                onClick={() => deleteComment(c.id, c.dsc)}
                              >
                                <FontAwesomeIcon icon="times-circle" />
                              </Button>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td>No Comments have yet been recorded</td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </Row>
              </TabPane>
              <TabPane tabId={TABS.HISTORY}>
                <HistoryList historyList={historyList} />
              </TabPane>
            </TabContent>
          </Card>
          <div className="px-5 py-3 d-flex justify-content-end">
            {hasBuildFeature && inventory.id > 0 ? (
              <Button size="lg" color="dark" className="me-2" onClick={onShowEditor}>
                Launch Layout Editor <FontAwesomeIcon icon="arrow-right" />
              </Button>
            ) : null}
            <Button color="primary" size="lg" onClick={saveInventoryItem}>
              {hasBuildFeature && (!inventory || !inventory.id) ? "Next" : "Save"}
            </Button>
          </div>

          {/*   COMMENTS     */}
          <Card className="p-3">
            <div>
              <Row className="p-1">
                <Col>
                  {inventory && inventory.id ? (
                    <Button
                      onClick={() => setShowCommentModal(true)}
                      size="sm"
                      className="bg-cf-success border-success float-end mb-2"
                    >
                      <FontAwesomeIcon icon="plus" className="me-1" />
                      Add Comment
                    </Button>
                  ) : null}
                  <Table size="sm">
                    <tbody>
                      {comments && comments.length ? (
                        _.map(comments, (c) => (
                          <tr
                            key={c.id}
                            className={classnames({ private: c.isPrivate }, "comment-row")}
                          >
                            <td className="content">{c.dsc}</td>
                            <td style={{ width: "180px" }}>{c.createdAtLocalized}</td>
                            <td style={{ width: "200px" }}>{c.userFullName}</td>
                            <td style={{ width: "50px" }}>
                              {(c.userId === currentUser.id || isSystemAdmin) &&
                                canManageComments && (
                                  <Button
                                    color="danger"
                                    className="text-light"
                                    size="sm"
                                    title="Delete"
                                    onClick={() => deleteComment(c.id, c.dsc)}
                                  >
                                    <FontAwesomeIcon icon="times-circle" />
                                  </Button>
                                )}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td>No Comments have yet been recorded</td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </div>
          </Card>
        </div>
      </div>
      <Footer />

      {showEditor && <div style={popOutStyle}>{renderFloorplan()}</div>}
    </div>
  );
}

export default InventoryEdit;
