import React, {useContext, useState, useEffect} from "react";
import { Link } from "react-router-dom";
import { Row, Button, Col, Container, UncontrolledCarousel } from "reactstrap";
import { Header, Footer, CalculatorModal } from "./";
import CalcImg from '../assets/img/calculator.png';
import {constants, UserContext} from "../utils";
import {api} from "../helpers";
import classnames from "classnames";
import Alert from "react-s-alert-v3";
import _ from "lodash";
const PATHNAMES = constants.PATH_NAMES;

const Home = () => {
  const userCtx = useContext(UserContext);
  const [ showCalc, setShowCalc ] = useState(false);
  const [systemMessages, setSystemMessages] = useState([]);

  document.title = `Home - ${constants.appName}`;

  useEffect(() => {
    api.post('Home/GetSystemMessages', {}).then(r => {
      setSystemMessages(_.map(r.data, x => ({
        altText: x.message,
        // caption: x.message,
        key: x.id,
        src: x.imageURL ? x.publicImageURL : "/Content/img/transp50.png"
      })));
    }).catch((err) => Alert.error("Could not load system messages"));
  }, []);

  return (
    <div className="home-page">
      <Header />
      <Container fluid>
        <div className="grid">
            <Button
              block
              className="grid-item card-1"
              tag={Link}
              to={PATHNAMES.RTOS}
            >
              Point of Sale
            </Button>
            <Button
              block
              color="primary"
              className="grid-item card-2"
              tag={Link}
              to={PATHNAMES.INVENTORY}
            >
              Inventory
            </Button>
            <Button
              block
              color="dark"
              className="grid-item card-3"
              tag={Link}
              onClick={() => setShowCalc(!showCalc)}
            >
              Calculator
            </Button>
            <CalculatorModal isOpen={showCalc} setIsOpen={setShowCalc} />
            <Button
              block
              color="dark"
              className="grid-item card-4"
              tag={Link}
              to={PATHNAMES.RESOURCES_DOCINDEX}
            >
              Resources
            </Button>
        </div>
          <div className="text-center">
            {systemMessages.length
              ? (<UncontrolledCarousel items={systemMessages} interval={10000} />)
              : null
            }
          </div>
        <Row className="mt-12 d-lg-none">
          <Col xs={{size: 10, offset: 1}}>
            <Row>
              <Col className="text-center fw-bold" style={{
                  backgroundColor: "black",
                  borderRadius: "8px 8px 0 0",
                  color: "#FFF"
              }}>
                Download Driver Mobile App
              </Col>
            </Row>
            <Row className="p-2" style={{border: "solid 1px #000", textAlign: "center"}}>
              <Col xs="12">
                <a href="/Content/files/opshub-production.apk" style={{textDecoration: "none"}} target="_blank" rel="noreferrer">
                  <img src="/Content/img/android-app.png" alt="Download app for Android" height="41" /></a>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
};

export default Home;
