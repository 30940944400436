import React, {Fragment, useState, useEffect} from "react";
import {
  Row,
  Col,
  FormGroup,
  Form,
  Input,
  Label,
  InputGroup,
  Button,
} from "reactstrap";
import moment from "moment";
import _ from "lodash";
import { api, ui_helpers } from "../helpers";
import classnames from 'classnames';
import { constants, storage } from "../utils";
import { useContractStore } from "../hooks";
import Select from 'react-select'
import CFLogo from "../assets/img/CFLogo.png";

const defaultCustomerData = (existing) => {
  if (!existing)
    existing = {};
    return {
    ...existing,
    firstName: (existing.firstName || ""),
    lastName: (existing.lastName || ""),
    customerPhone1: (existing.customerPhone1 || ""),
    secondaryPhone: (existing.secondaryPhone || ""),
    email: (existing.email || ""),
    ssn: (existing.ssn || ""),
    licenseNo: (existing.licenseNo || ""),
    dateOfBirth: (existing.dateOfBirth || ""),
    dealer: existing.dealer,
    region: existing.region,
    culture: existing.culture,
    deliveryName: (existing.deliveryName || ""),
    deliveryAddress1: (existing.deliveryAddress1 || ""),
    deliveryAddress2: (existing.deliveryAddress2 || ""),
    deliveryCity: (existing.deliveryCity || ""),
    deliveryState: (existing.deliveryState || ""),
    deliveryZip: (existing.deliveryZip || ""),
    billingName: (existing.billingName || ""),
    billingAddress1: (existing.billingAddress1 || ""),
    billingAddress2: (existing.billingAddress2 || ""),
    billingCity: (existing.billingCity || ""),
    billingState: (existing.billingState || ""),
    billingZip: (existing.billingZip || "")
  };
};

const CustomerInformation = (props) => {
  const contractDealerId = useContractStore(x => x.dealerId);
  const contractCompanyId = useContractStore(x => x.companyId);
  const contractRegionId = useContractStore(x => x.regionId);
  const setRegionId = useContractStore((x) => x.setRegionId);
  const setCompanyId = useContractStore((x) => x.setCompanyId);
  const setDealerId = useContractStore((x) => x.setDealerId);

  const [customerData, setCustomerData] = useState(defaultCustomerData(props.contractData));
  const [screenWarnings, setScreenWarnings] = useState({});

  useEffect(() => {
    setCustomerData(defaultCustomerData(props.contractData));
  }, [props.contractData])

  useEffect(() => {
    let newScreenWarnings = {};
    _.each(props.fieldWarnings, w => Object.assign(newScreenWarnings, {[w.field]: w.text}))
    setScreenWarnings(newScreenWarnings);
  }, [props.fieldWarnings])

  function copyDeliveryToBilling() {
    let newCustomerData = Object.assign({}, customerData);
    newCustomerData.billingName = customerData.deliveryName;
    newCustomerData.billingAddress1 = customerData.deliveryAddress1;
    newCustomerData.billingAddress2 = customerData.deliveryAddress2;
    newCustomerData.billingCity = customerData.deliveryCity;
    newCustomerData.billingState = customerData.deliveryState;
    newCustomerData.billingZip = customerData.deliveryZip;
    setCustomerData(newCustomerData);
    props.onSetData(newCustomerData);
  }

  // useConsole('customer information: ', customerData);

  function onBlur(value, fieldName) {
    switch(fieldName) {
      case 'dateOfBirth':
        setCustomerData({
          ...customerData,
          [fieldName]: ui_helpers.formatDateField(value)
        });
      break;
      case 'ssn':
        setCustomerData({
          ...customerData,
          [fieldName]: ui_helpers.formatSSNField(value)
        });
      break;
      case 'mobilePhone':
      case 'secondaryPhone':
      case 'customerPhone1':
      case 'customerPhone2':
        setCustomerData({
          ...customerData,
          [fieldName]: ui_helpers.formatPhoneField(value)
        });
      break;
      case 'billingAddress1':
      case 'billingAddress2':
      case 'billingCity':
      case 'deliveryAddress1':
      case 'deliveryAddress2':
      case 'deliveryCity':
        setCustomerData({
          ...customerData,
          [fieldName]: ui_helpers.capitalizeFirstLetter(value)
        });
        break;
      case 'billingState':
      case "deliveryState":
        setCustomerData({
          ...customerData,
          [fieldName]: ui_helpers.capitalizeAllLetters(value)
        });
        break;
      case 'firstName':
      case 'lastName':
        value = ui_helpers.capitalizeFirstLetter(value)
        var newData = {
          ...customerData,
          [fieldName]: value
        };
        if (value)
        {
          const proposedValue = fieldName === 'firstName'
            ? value + ' ' + customerData.lastName
            : customerData.firstName + ' ' + value;
          const minLen = _.min([_.trim(proposedValue).length, customerData.deliveryName ? customerData.deliveryName.length : 0]);
          if (minLen === 0 || _.startsWith(customerData.deliveryName, proposedValue.substring(0, minLen))) {
            newData.deliveryName = proposedValue;
          }
        }
        setCustomerData(newData);
      break;
    }
  }

  function propagateNewData(newData) {
    setCustomerData(newData);
    props.onSetData(newData);
  }

  function maybeUpdateCompanyAndPropagateChanges(newData, newRegionId = null, newDealerId = null) {
    if (props.contractType !== constants.CONTRACT_TYPE.RTO) {
      propagateNewData(newData);
      return;
    }
    let resolvedRegionId = newRegionId
      ? newRegionId
      : contractRegionId;
    let resolvedDealerId = newDealerId
      ? newDealerId
      : contractDealerId;
    api.post('Contract/GetRTOCompanyIdByDealerRegion', 
        {dealerId: resolvedDealerId, regionId: resolvedRegionId}).then(r => {
      const newCompanyId = r.data.message
        ? parseInt(r.data.message, 10)
        : null;
      if (r.data.success && contractCompanyId !== newCompanyId) {
        setCompanyId(newCompanyId); // zustand
        newData.companyId = newCompanyId;
      }
      propagateNewData(newData);
    }).catch(err => console.error(err));
  }

  function onChange(value, fieldName) {
    const newData = {
      ...customerData,
      [fieldName]: value
    };
    if (fieldName === 'region') {
      // remember to default for next time
      // and if we're a contract resolve related dealer/regions to possibly reset the company relation
      storage.setItem(constants.STORAGE_KEYS.LAST_REGION_ID, value.value);
      setRegionId(value.value); // zustand
      maybeUpdateCompanyAndPropagateChanges(newData, value.value, null);
    }
    else if (fieldName === 'dealer')
    {
      setDealerId(value.value); // zustand
      maybeUpdateCompanyAndPropagateChanges(newData, null, value.value);
    } 
    else
    {
      propagateNewData(newData);
    }
  }

  return (
    <Form className="mt-2">
      <Row>
        <Col>
          <div className="mb-2">
            <Label>
              First Name {ui_helpers.requiredStar()}
            </Label>
            <Input type="text" bsSize="sm" maxLength="25" value={customerData.firstName} autoComplete="false"
              title={screenWarnings.firstName ? screenWarnings.firstName : null}
              className={classnames({invalid: (screenWarnings.firstName ? true : false)})}
              onBlur={(e) => onBlur(e.target.value, 'firstName')} onChange={(e) => onChange(e.target.value, 'firstName')} />
          </div>
          <div className="mb-2">
            <Label>
              Last Name {ui_helpers.requiredStar()}
            </Label>
            <Input type="text" bsSize="sm" maxLength="25" value={customerData.lastName} autoComplete="false"
              title={screenWarnings.lastName ? screenWarnings.lastName : null}
              className={classnames({invalid: (screenWarnings.lastName ? true : false)})}
              onBlur={(e) => onBlur(e.target.value, 'lastName')} onChange={(e) => onChange(e.target.value, 'lastName')} />
          </div>
          <div className="mb-2">
            <Label>Cell Phone  {props.isQuote ? null : ui_helpers.requiredStar()}</Label>
            <Input type="tel" bsSize="sm" maxLength="20" value={customerData.customerPhone1} autoComplete="false"
              title={screenWarnings.customerPhone1 ? screenWarnings.customerPhone1 : null}
              className={classnames({invalid: (screenWarnings.customerPhone1 ? true : false)})}
              onBlur={(e) => onBlur(e.target.value, 'customerPhone1')} onChange={(e) => onChange(e.target.value, 'customerPhone1')} />
          </div>
          <div className="mb-2">
            <Label>Secondary Phone</Label>
            <Input type="tel" bsSize="sm" maxLength="20" value={customerData.secondaryPhone} autoComplete="false"
              title={screenWarnings.secondaryPhone ? screenWarnings.secondaryPhone : null}
              className={classnames({invalid: (screenWarnings.secondaryPhone ? true : false)})}
              onBlur={(e) => onBlur(e.target.value, 'secondaryPhone')} onChange={(e) => onChange(e.target.value, 'secondaryPhone')} />
          </div>
          <div className="mb-2">
            <Label>Email</Label>
            <Input type="email" bsSize="sm" maxLength="150" value={customerData.email} autoComplete="false"
              title={screenWarnings.email ? screenWarnings.email : null}
              className={classnames({invalid: (screenWarnings.email ? true : false)})}
              onChange={(e) => onChange(e.target.value, 'email')} />
          </div>
        </Col>
        <Col className={props.isQuote ? "form-col-even-quote" : "form-col-even"}>
        {props.contractType === constants.CONTRACT_TYPE.RTO
          ? (<Fragment>
              <div className="mb-2">
                <div className="float-end text-muted" style={{fontSize: "0.75rem"}}>
                  If no SSN # is available, a license number is required
                </div>
                <Label>SSN</Label>
                <InputGroup size="sm">
                  <Input type="text" value={customerData.ssn} maxLength="11" autoComplete="false"
                    title={screenWarnings.ssn ? screenWarnings.ssn : null}
                    className={classnames({invalid: (screenWarnings.ssn ? true : false)})}
                    onBlur={(e) => onBlur(e.target.value, 'ssn')} onChange={(e) => onChange(e.target.value, 'ssn')} />
                  <Button color="info" className="text-light" onClick={() => props.onFindCustomer(customerData.ssn, '')}>
                    Find Existing
                  </Button>
                </InputGroup>
              </div>
              <div className="mb-2">
                <Label>License #</Label>
                <InputGroup size="sm">
                  <Input type="text" value={customerData.licenseNo} maxLength="20" autoComplete="false"
                    title={screenWarnings.licenseNo ? screenWarnings.licenseNo : null}
                    className={classnames({invalid: (screenWarnings.licenseNo ? true : false)})}
                    onChange={(e) => onChange(e.target.value, 'licenseNo')} />
                  <Button color="info" className="text-light" onClick={() => props.onFindCustomer('', customerData.licenseNo)}>
                    Find Existing
                  </Button>
                </InputGroup>
              </div>
              <div className="mb-2">
                <Label>
                  Date of Birth {ui_helpers.requiredStar()}
                </Label>
                <Input type="text" bsSize="sm" value={customerData.dateOfBirth} 
                  autoComplete="false"
                  title={screenWarnings.dateOfBirth ? screenWarnings.dateOfBirth : null}
                  className={classnames({invalid: (screenWarnings.dateOfBirth ? true : false)})}
                  onBlur={(e) => onBlur(e.target.value, 'dateOfBirth')} onChange={(e) => onChange(e.target.value, 'dateOfBirth')} />
              </div>
             </Fragment>)
          : null
        }
          <div className="mb-2">
            <Label>Store</Label>
            <div title={screenWarnings.dealer ? screenWarnings.dealer : null}>
              <Select
                options={props.dealers}
                className={classnames({invalid: (screenWarnings.dealer ? true : false)})}
                value={customerData.dealer}
                onChange={(x) => onChange(x, 'dealer')}
              />
            </div>
          </div>
          <div className="mb-2">
            <Label>Region {ui_helpers.requiredStar()}</Label>
            <div title={screenWarnings.region ? screenWarnings.region : null}>
              <Select
                options={props.regions}
                value={customerData.region}
                onChange={(x) => onChange(x, 'region')}
                classNamePrefix={screenWarnings.region ? "invalidSelect" : null}
              />
            </div>
          </div>
          <div className="mb-2">
            <Label>Preferred Contract Language</Label>
            <div title={screenWarnings.culture ? screenWarnings.culture : null}>
              <Select
                options={props.cultures}
                className={classnames({invalid: (screenWarnings.culture ? true : false)})}
                value={customerData.culture}
                onChange={(x) => onChange(x, 'culture')}
              />
            </div>
          </div>
        </Col>
        <Col>
          <Label>
            Delivery Address {props.isQuote ? null : ui_helpers.requiredStar()}
          </Label>
          <div className="mb-1">
            <Input type="text" bsSize="sm" maxLength="100" autoComplete="off" placeholder="Delivery Name" 
              title={screenWarnings.deliveryName ? screenWarnings.deliveryName : null}
              className={classnames({invalid: (screenWarnings.deliveryName ? true : false)})}
              value={customerData.deliveryName} onChange={(e) => onChange(e.target.value, 'deliveryName')} />
          </div>
          <div className="mb-1">
            <Input type="text" bsSize="sm" maxLength="100" placeholder="Delivery Address 1" autoComplete="false"
              title={screenWarnings.deliveryAddress1 ? screenWarnings.deliveryAddress1 : null}
              className={classnames({invalid: (screenWarnings.deliveryAddress1 ? true : false)})}
              value={customerData.deliveryAddress1} onChange={(e) => onChange(e.target.value, 'deliveryAddress1')}
              onBlur={(e) => onBlur(e.target.value, 'deliveryAddress1')} />
          </div>
          <div className="mb-1">
            <Input
              type="text"
              bsSize="sm"
              maxLength="100"
              placeholder="Delivery Address 2 (optional)"
              autoComplete="false"
              value={customerData.deliveryAddress2} onChange={(e) => onChange(e.target.value, 'deliveryAddress2')} 
              onBlur={(e) => onBlur(e.target.value, 'deliveryAddress2')} 
            />
          </div>
          <div>
            <Row>
              <Col>
                <Input bsSize="sm" maxLength="50" type="text" placeholder="City" autoComplete="false" style={{float: "left", width: "58%"}}
                  title={screenWarnings.deliveryCity ? screenWarnings.deliveryCity : null}
                  className={classnames({invalid: (screenWarnings.deliveryCity ? true : false)})}
                  value={customerData.deliveryCity} onChange={(e) => onChange(e.target.value, 'deliveryCity')} 
                  onBlur={(e) => onBlur(e.target.value, 'deliveryCity')} />
                <Input bsSize="sm" maxLength="2" type="text" placeholder="ST" autoComplete="false" style={{float: "left", width: "4rem", marginLeft: "2px"}}
                  title={screenWarnings.deliveryState ? screenWarnings.deliveryState : null}
                  className={classnames({invalid: (screenWarnings.deliveryState ? true : false)})}
                  value={customerData.deliveryState} onChange={(e) => onChange(e.target.value, 'deliveryState')} 
                  onBlur={(e) => onBlur(e.target.value, 'deliveryState')} />
                <Input bsSize="sm" maxLength="20" type="text" placeholder="Zip" autoComplete="false" style={{float: "left", width: "5rem", marginLeft: "2px"}}
                  title={screenWarnings.deliveryZip ? screenWarnings.deliveryZip : null}
                  className={classnames({invalid: (screenWarnings.deliveryZip ? true : false)})}
                  value={customerData.deliveryZip} onChange={(e) => onChange(e.target.value, 'deliveryZip')} />
              </Col>
            </Row>
          </div>
        </Col>
        <Col className={props.isQuote ? "form-col-even-quote" : "form-col-even"}>
          <Row>
            <Col>
              <Label>
                Billing Address {props.isQuote ? null : ui_helpers.requiredStar()}
              </Label>
            </Col>
            <Col>
              <Button color="info" size="sm" className="float-end text-light" style={{padding: "1px 6px"}} onClick={copyDeliveryToBilling}>
                Set Same as Delivery
              </Button>
            </Col>
          </Row>
          <div className="mb-1">
            <Input type="text" bsSize="sm" maxLength="100" autoComplete="off" placeholder="Billing Name" 
              title={screenWarnings.billingName ? screenWarnings.billingName : null}
              className={classnames({invalid: (screenWarnings.billingName ? true : false)})}
              value={customerData.billingName} onChange={(e) => onChange(e.target.value, 'billingName')} />
          </div>
          <div className="mb-1">
            <Input type="text" bsSize="sm" maxLength="100" placeholder="Billing Address 1" autoComplete="false"
              title={screenWarnings.billingAddress1 ? screenWarnings.billingAddress1 : null}
              className={classnames({invalid: (screenWarnings.billingAddress1 ? true : false)})}
              value={customerData.billingAddress1} onChange={(e) => onChange(e.target.value, 'billingAddress1')} />
          </div>
          <div className="mb-1">
            <Input
              type="text"
              bsSize="sm" maxLength="100"
              placeholder="Billing Address 2 (optional)" autoComplete="false"
              value={customerData.billingAddress2} onChange={(e) => onChange(e.target.value, 'billingAddress2')}
            />
          </div>
          <div>
            <Row>
              <Col>
                <Input bsSize="sm" maxLength="50" type="text" placeholder="City" autoComplete="false" style={{float: "left", width: "58%"}}
                  title={screenWarnings.billingCity ? screenWarnings.billingCity : null}
                  className={classnames({invalid: (screenWarnings.billingCity ? true : false)})}
                  value={customerData.billingCity} onChange={(e) => onChange(e.target.value, 'billingCity')} />
                <Input bsSize="sm" maxLength="2" type="text" placeholder="ST" autoComplete="false" style={{float: "left", width: "4rem", marginLeft: "2px"}}
                  title={screenWarnings.billingState ? screenWarnings.billingState : null}
                  className={classnames({invalid: (screenWarnings.billingState ? true : false)})}
                  value={customerData.billingState} onChange={(e) => onChange(e.target.value, 'billingState')} />
                <Input bsSize="sm" maxLength="20" type="text" placeholder="Zip" autoComplete="false" style={{float: "left", width: "5rem", marginLeft: "2px"}}
                  title={screenWarnings.billingZip ? screenWarnings.billingZip : null}
                  className={classnames({invalid: (screenWarnings.billingZip ? true : false)})}
                  value={customerData.billingZip} onChange={(e) => onChange(e.target.value, 'billingZip')} />
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      {props.contractType === constants.CONTRACT_TYPE.RTO || props.isQuote ? null : (
        <Row>
          <Col >
            <div className="mb-2">
              Click to select the company to own this cash sale:
            </div>
            {props.ownershipCompanyList && props.ownershipCompanyList.length
                ? (_.map(props.ownershipCompanyList, c => (
                    <span key={c.id} 
                      className={`owner-img company-panel ${customerData.companyId === c.id ? "active" : ""}`} 
                      title={c.name}
                      onClick={() => onChange(c.id, 'companyId')}
                    >
                      <img src={`api/public/GetCompanyLogo/${c.id}`} alt={`${c.name} Logo`} />
                    </span>
                    )))
               : (<span className="owner-img active"><img src={CFLogo} className="owner-img" alt="Carefree Logo" /></span>)}
          </Col>
        </Row>
      )}
    </Form>
  );
};

export default CustomerInformation;
